<template>
	<section>
		<b-row>
			<b-col cols="12">
				<app-collapse type="margin">
					<app-collapse-item :title="proposalWeightings[0].proposal_name">
						<template #header>
							<span class="lead collapse-title">
								<strong>{{ proposalWeightings[0].proposal_name }}</strong>
							</span>
							<span class="text-primary small ml-1">
								<strong>{{ proposalWeightings[0].weighting }}</strong>
							</span>
						</template>

						<template
							#buttons
							v-if="!isPublished"
						>
							<b-button
								v-ripple.400="'rgba(40, 199, 111, 0.15)'"
								variant="flat-info"
								class="btn-icon rounded-circle"
								v-b-tooltip.hover.v-info
								title="Editar Peso"
								@click.stop="openProposalModal(true, false, false)"
							>
								<feather-icon icon="Edit3Icon" />
							</b-button>
							<b-button
								v-ripple.400="'rgba(40, 199, 111, 0.15)'"
								variant="flat-info"
								class="btn-icon rounded-circle"
								v-b-tooltip.hover.v-info
								title="Editar Descripción"
								@click.stop="openDocumentModal(true, false, false)"
							>
								<feather-icon icon="FileTextIcon" />
							</b-button>
						</template>

						<div
							v-if="!economicProposal.description"
							class="text-muted"
						>Sin descripción</div>

						<div
							v-else
							v-html="economicProposal.description"
						></div>
					</app-collapse-item>

					<app-collapse-item :title="proposalWeightings[1].proposal_name">
						<template #header>
							<span class="lead collapse-title">
								<strong>{{ proposalWeightings[1].proposal_name }}</strong>
							</span>
							<span class="text-primary small ml-1">
								<strong>{{ proposalWeightings[1].weighting }}</strong>
							</span>
						</template>

						<template
							#buttons
							v-if="!isPublished"
						>
							<b-button
								v-ripple.400="'rgba(40, 199, 111, 0.15)'"
								variant="flat-info"
								class="btn-icon rounded-circle"
								v-b-tooltip.hover.v-info
								title="Editar Peso"
								@click.stop="openProposalModal(false, true, false)"
							>
								<feather-icon icon="Edit3Icon" />
							</b-button>
							<b-button
								v-ripple.400="'rgba(40, 199, 111, 0.15)'"
								variant="flat-info"
								class="btn-icon rounded-circle"
								v-b-tooltip.hover.v-info
								title="Agregar"
								@click.stop="openDocumentModal(false, true, false)"
							>
								<feather-icon icon="FilePlusIcon" />
							</b-button>
						</template>

						<div
							class="d-flex align-items-center mb-1"
							v-for="(document, index) in formData.sigDocuments"
							:key="index"
						>
							<b-form-checkbox
								name="technical_documents"
								:checked="document.is_required === 1"
								:disabled="!!isPublished"
								@change="toggleRequired(document)"
							>
								<span
									class="small"
									:class="{'text-muted': !document.is_required }"
								>{{ document.name }}</span>
							</b-form-checkbox>
							<span class="small text-info ml-1">{{ document.weighting || 0 }}</span>
							<div
								class="d-flex align-items-center text-nowrap"
								v-if="!isPublished"
							>
								<span>
									<feather-icon
										:id="`document-sig-${index}-edit-icon`"
										icon="Edit2Icon"
										class="cursor-pointer text-secondary"
										style="margin-right: 5px; margin-left: 10px"
										size="14"
										@click.stop="editDocument(index, true, false)"
									/>
									<b-tooltip
										variant="secondary"
										triggers="hover"
										title="Editar"
										placement="left"
										class="cursor-pointer"
										:target="`document-sig-${index}-edit-icon`"
									/>
								</span>
								<span v-if="document.is_new">
									<feather-icon
										:id="`document-sig-${index}-delete-icon`"
										icon="TrashIcon"
										class="cursor-pointer text-secondary"
										size="14"
										@click="removeDocument(index, true, false)"
									/>
									<b-tooltip
										variant="secondary"
										triggers="hover"
										title="Eliminar"
										placement="right"
										class="cursor-pointer"
										:target="`document-sig-${index}-delete-icon`"
									/>
								</span>
							</div>
						</div>
					</app-collapse-item>

					<app-collapse-item :title="proposalWeightings[2].proposal_name">
						<template #header>
							<span class="lead collapse-title">
								<strong>{{ proposalWeightings[2].proposal_name }}</strong>
							</span>
							<span class="text-primary small ml-1">
								<strong>{{ proposalWeightings[2].weighting }}</strong>
							</span>
						</template>

						<template
							#buttons
							v-if="!isPublished"
						>
							<b-button
								v-ripple.400="'rgba(40, 199, 111, 0.15)'"
								variant="flat-info"
								class="btn-icon rounded-circle"
								v-b-tooltip.hover.v-info
								title="Editar Peso"
								@click.stop="openProposalModal(false, false, true)"
							>
								<feather-icon icon="Edit3Icon" />
							</b-button>
							<b-button
								v-ripple.400="'rgba(40, 199, 111, 0.15)'"
								variant="flat-info"
								class="btn-icon rounded-circle"
								v-b-tooltip.hover.v-info
								title="Agregar"
								@click.stop="openDocumentModal(false, false, true)"
							>
								<feather-icon icon="FilePlusIcon" />
							</b-button>
						</template>

						<div
							class="d-flex align-items-center mb-1"
							v-for="(document, index) in formData.tecDocuments"
							:key="index"
						>
							<b-form-checkbox
								name="technical_documents"
								:checked="document.is_required === 1"
								:disabled="!!isPublished"
								@change="toggleRequired(document)"
							>
								<span
									class="small"
									:class="{'text-muted': !document.is_required }"
								>{{ document.name }}</span>
							</b-form-checkbox>
							<span class="small text-info ml-1">{{ document.weighting || 0 }}</span>
							<div
								class="d-flex align-items-center text-nowrap"
								v-if="!isPublished"
							>
								<span>
									<feather-icon
										:id="`document-tec-${index}-edit-icon`"
										icon="Edit2Icon"
										class="cursor-pointer text-secondary"
										style="margin-right: 5px; margin-left: 10px"
										size="14"
										@click.stop="editDocument(index, false, true)"
									/>
									<b-tooltip
										variant="secondary"
										placement="left"
										triggers="hover"
										title="Editar"
										class="cursor-pointer"
										:target="`document-tec-${index}-edit-icon`"
									/>
								</span>
								<span v-if="document.is_new">
									<feather-icon
										:id="`document-tec-${index}-delete-icon`"
										icon="TrashIcon"
										class="cursor-pointer text-secondary"
										size="14"
										@click="removeDocument(index, false, true)"
									/>
									<b-tooltip
										variant="secondary"
										placement="right"
										triggers="hover"
										title="Eliminar"
										class="cursor-pointer"
										:target="`document-tec-${index}-delete-icon`"
									/>
								</span>
							</div>
						</div>
					</app-collapse-item>
				</app-collapse>
			</b-col>

			<b-col
				cols="12"
				class="mt-1"
			>
				<hr />
			</b-col>

			<b-col
				cols="12"
				class="d-flex flex-column flex-sm-row justify-content-sm-end mb-1"
			>
				<!-- BUTTON SUBMIT -->
				<b-button-loading
					v-if="!isPublished"
					text="GUARDAR CAMBIOS"
					type="button"
					class="order-sm-2 order-1 ml-sm-2 ml-0"
					:processing="processing"
					@process-action="saveInformation"
				>></b-button-loading>
			</b-col>
		</b-row>

		<b-modal
			ref="refProposalModal"
			title-tag="div"
			header-bg-variant="primary"
			header-text-variant="white"
			centered
			size="md"
			title="ACTUALIZAR PESO DE PROPUESTA"
			no-close-on-backdrop
			no-close-on-esc
			hide-header-close
		>
			<b-form>
				<b-row>
					<b-col
						cols="12"
						class="mb-1"
					>
						<label for="tipo">
							<strong>Peso de propuesta</strong>
						</label>
						<b-form-input
							id="tipo"
							type="number"
							v-model="weighting"
							placeholder="0"
							trim
						/>
					</b-col>
				</b-row>
			</b-form>

			<template #modal-footer="{ cancel }">
				<b-button
					@click="saveProposalModal()"
					variant="outline-primary"
				>ACTUALIZAR</b-button>

				<b-button
					v-ripple.400="'rgba(186, 191, 199, 0.15)'"
					type="button"
					variant="outline-secondary"
					class="ml-2"
					@click="cancel"
				>CERRAR</b-button>
			</template>
		</b-modal>

		<b-modal
			ref="refDocumentModal"
			title-tag="div"
			header-bg-variant="primary"
			header-text-variant="white"
			centered
			:title="isEconomic ? 'ACTUALIZAR INFORMACIÓN' : `${ isEditing ? 'EDITAR' : 'AGREGAR'} CATEGORÍA`"
			:size="isEconomic ? 'lg' : 'md'"
			no-close-on-backdrop
			no-close-on-esc
			hide-header-close
		>
			<b-form>
				<b-row>
					<template v-if="!isEconomic">
						<b-col
							cols="12"
							class="mb-1"
						>
							<label for="tipo">
								<strong>Peso de categoría</strong>
							</label>
							<b-form-input
								id="tipo"
								type="number"
								v-model="documentForm.weighting"
								placeholder="0"
								trim
							/>
						</b-col>

						<b-col
							cols="12"
							v-if="documentVisible"
						>
							<label for="tipo">
								<strong>Categoría</strong>
							</label>
							<b-form-group>
								<b-form-input
									id="basicInput"
									v-model="documentForm.name"
									placeholder="Ingrese nueva categoría ..."
								/>
							</b-form-group>
						</b-col>
					</template>

					<b-col
						cols="12"
						v-else
					>
						<label for="description">
							<strong>Descripción</strong>
						</label>
						<b-form-group>
							<quill-editor
								id="description"
								v-model="documentForm.description"
								:options="snowOption"
								placeholder="Ingrese la descripción aquí ..."
							/>
						</b-form-group>
					</b-col>
				</b-row>
			</b-form>

			<template #modal-footer>
				<b-button
					@click="saveDocument()"
					variant="outline-primary"
				>{{ isEconomic ? 'ACTUALIZAR' : isEditing ? 'EDITAR' : 'AGREGAR'}}</b-button>

				<b-button
					v-ripple.400="'rgba(186, 191, 199, 0.15)'"
					type="button"
					variant="outline-secondary"
					class="ml-2"
					@click="closeDocumentModal()"
				>CERRAR</b-button>
			</template>
		</b-modal>
	</section>
</template>

<script>
import {
	ref,
	onBeforeMount,
	onUnmounted,
	watch,
	onMounted
} from "@vue/composition-api"
import { VBTooltip } from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import router from "@/router"
import axios from "@axios"
import { quillEditor } from "vue-quill-editor"
import "quill/dist/quill.core.css"
import "quill/dist/quill.snow.css"
import "quill/dist/quill.bubble.css"

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue"
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import useNotifications from "@notifications"

export default {
	components: {
		AppCollapse,
		AppCollapseItem,
		BButtonLoading,

		quillEditor
	},
	directives: {
		"b-tooltip": VBTooltip,
		Ripple
	},
	props: {
		tenderId: {
			type: Number,
			require: true
		},
		tenderDocuments: {
			type: Array,
			default: () => [],
			require: true
		},
		proposalWeightings: {
			type: Array,
			default: () => [],
			require: true
		},
		economicProposal: {
			type: Object,
			default: () => {},
			require: true
		},
		isPublished: {
			type: Number,
			require: true
		}
	},
	setup(props) {
		const { toastNotification } = useNotifications()

		const SIG_ID = 2
		const TEC_ID = 3
		//REFS
		const tenderId = ref(props.tenderId)
		const tenderDocuments = ref(props.tenderDocuments)
		const proposalWeightings = ref(props.proposalWeightings)
		const economicProposal = ref(props.economicProposal)
		const refProposalModal = ref(null)
		const refDocumentModal = ref(null)
		const isEconomic = ref(false)
		const isSig = ref(false)
		const isTechnical = ref(false)
		const documentForm = ref({})
		const formData = ref({
			sigDocuments: [],
			tecDocuments: []
		})
		const weighting = ref(null)
		const documentVisible = ref(true)
		const isEditing = ref(false)
		const processing = ref(false)

		const deletedDocuments = ref([])
		const indexOfDocument = ref(null)
		const snowOption = ref({
			theme: "snow"
		})

		onMounted(() => {
			setData()
		})

		const setData = () => {
			const sigDocuments = tenderDocuments.value.filter(
				(item) => item.proposal_type_id === SIG_ID
			)
			const tecDocuments = tenderDocuments.value.filter(
				(item) => item.proposal_type_id === TEC_ID
			)

			formData.value = {
				sigDocuments,
				tecDocuments
			}
		}

		const openProposalModal = (
			economic = false,
			sig = false,
			technical = false
		) => {
			if (economic) {
				isEconomic.value = economic
				weighting.value = proposalWeightings.value[0].weighting
			}
			if (sig) {
				isSig.value = sig
				weighting.value = proposalWeightings.value[1].weighting
			}
			if (technical) {
				isTechnical.value = technical
				weighting.value = proposalWeightings.value[2].weighting
			}

			refProposalModal.value.show()
		}

		const saveProposalModal = async () => {
			const id = isEconomic.value
				? proposalWeightings.value[0].id
				: isSig.value
				? proposalWeightings.value[1].id
				: proposalWeightings.value[2].id

			try {
				const payload = {
					weighting: weighting.value,
					tender_id: tenderId.value,
					published: false
				}
				const response = await axios.put(`/proposal-weightings/${id}`, payload)

				toastNotification(response.data.message)

				const index = isEconomic.value ? 0 : isSig.value ? 1 : 2
				proposalWeightings.value[index].weighting = parseFloat(
					weighting.value
				).toFixed(2)
			} catch (error) {
				toastNotification(error.response.data.message, false)
			}

			resetModal()
			refProposalModal.value.hide()
		}

		const openDocumentModal = (
			economic = false,
			sig = false,
			technical = false
		) => {
			if (economic) {
				isEconomic.value = economic
				documentForm.value.description = economicProposal.value.description
			}
			isSig.value = sig
			isTechnical.value = technical

			refDocumentModal.value.show()
		}

		const closeDocumentModal = () => {
			resetModal()

			refDocumentModal.value.hide()
		}

		const editDocument = (index, sig = false, technical = false) => {
			isEditing.value = true
			indexOfDocument.value = index
			isSig.value = sig
			isTechnical.value = technical

			let document = null
			if (isSig.value) document = formData.value.sigDocuments[index]
			if (isTechnical.value) document = formData.value.tecDocuments[index]
			if (!document.is_new) documentVisible.value = false

			documentForm.value.name = document.name
			documentForm.value.weighting = parseFloat(document.weighting).toFixed(2)

			refDocumentModal.value.show()
		}

		const removeDocument = (index, sig = false, technical = false) => {
			let document = null

			if (sig) {
				document = formData.value.sigDocuments[index]
				formData.value.sigDocuments.splice(index, 1)
			}
			if (technical) {
				document = formData.value.tecDocuments[index]
				formData.value.tecDocuments.splice(index, 1)
			}
			if (document && document.id) {
				deletedDocuments.value.push(document)
			}
		}

		const saveDocument = async () => {
			if (
				!isEconomic.value &&
				(!documentForm.value.name || !documentForm.value.weighting)
			) {
				toastNotification("Complete los campos", false)
				return
			}

			if (isEconomic.value && !documentForm.value.description) {
				toastNotification("Complete el campo", false)
				return
			}

			if (isEconomic.value) {
				try {
					const payload = {
						description: documentForm.value.description,
						tender_id: tenderId.value,
						published: false
					}
					const response = await axios.put(
						`/economic-proposals/${economicProposal.value.id}`,
						payload
					)
					toastNotification(response.data.message)
				} catch (error) {
					toastNotification(error.response.data.message, false)
				}

				economicProposal.value.description = documentForm.value.description

				resetModal()
				refDocumentModal.value.hide()
			}

			if (isEditing.value) {
				if (isSig.value) {
					formData.value.sigDocuments[indexOfDocument.value].name = (
						documentForm.value.name || ""
					).toUpperCase()
					formData.value.sigDocuments[indexOfDocument.value].weighting =
						parseFloat(documentForm.value.weighting).toFixed(2)
				}
				if (isTechnical.value) {
					formData.value.tecDocuments[indexOfDocument.value].name = (
						documentForm.value.name || ""
					).toUpperCase()
					formData.value.tecDocuments[indexOfDocument.value].weighting =
						parseFloat(documentForm.value.weighting).toFixed(2)
				}

				resetModal()
				refDocumentModal.value.hide()
				return
			}

			const data = {
				name: (documentForm.value.name || "").toUpperCase(),
				weighting: parseFloat(documentForm.value.weighting).toFixed(2),
				is_required: 1,
				is_new: 1
			}

			if (isSig.value)
				formData.value.sigDocuments.push({ ...data, proposal_type_id: SIG_ID })
			if (isTechnical.value)
				formData.value.tecDocuments.push({ ...data, proposal_type_id: TEC_ID })

			resetModal()
			refDocumentModal.value.hide()
		}

		const resetModal = () => {
			isEconomic.value = false
			isSig.value = false
			isTechnical.value = false
			isEditing.value = false
			documentVisible.value = true
			indexOfDocument.value = null
			weighting.value = null
			documentForm.value = {
				name: null,
				weighting: null,
				descreiption: null
			}
		}

		const toggleRequired = (document) => {
			document.is_required = document.is_required === 1 ? 0 : 1
		}

		const saveInformation = async () => {
			processing.value = true

			try {
				const payload = {
					published: false,
					tender_id: tenderId.value,
					deletedDocuments: deletedDocuments.value,
					selectedDocuments: [
						...formData.value.sigDocuments,
						...formData.value.tecDocuments
					]
				}
				const response = await axios.put("/tender/documents/", payload)
				toastNotification(response.data.message)
			} catch (error) {
				toastNotification(error.response.data.message, false)
			} finally {
				processing.value = false
			}
		}

		return {
			//REFS
			refProposalModal,
			refDocumentModal,
			isTechnical,
			isEconomic,
			isSig,
			documentForm,
			formData,
			weighting,
			documentVisible,
			isEditing,
			processing,

			snowOption,

			//METHODS
			toggleRequired,

			openProposalModal,
			saveProposalModal,
			openDocumentModal,
			closeDocumentModal,
			editDocument,
			removeDocument,
			saveDocument,
			saveInformation
		}
	}
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>