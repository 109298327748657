import axios from '@axios';

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchParticipants(ctx, { id, params }) {
			return new Promise((resolve, reject) => {
				axios
					.get(`/tenders/${id}/participants`, { params })
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		addParticipants(ctx, { payload }) {
			return new Promise((resolve, reject) => {
				axios
					.post(`/invitations`, payload)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		deleteParticipant(ctx, { id, payload }) {
			return new Promise((resolve, reject) => {
				axios
					.delete(`/invitations/${id}?tender_id=${payload.tender_id}&published=${false}`)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		resendInvitation(ctx, { id, payload }) {
			return new Promise((resolve, reject) => {
				axios
					.put(`/invitations/${id}/resend`, payload)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		saveComment(ctx, { id, payload }) {
			return new Promise((resolve, reject) => {
				axios
					.put(`/invitations/${id}/comment`, payload)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
	}
}