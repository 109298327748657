import { ref, watch, computed } from '@vue/composition-api';
import router from "@/router"
import store from '@/store';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useTenderEditTabParticipants(id) {
	// Use toast
	const toast = useToast();

	const refParticipantsListTable = ref(null);

	const styleColumns = {
		thClass: 'text-center',
		tdClass: 'text-center'
	}

	// Table Handlers
	const tableColumns = [
		{ key: 'bidder_name', sortable: true, label: 'POSTOR', thClass: styleColumns.thClass },
		{ key: 'bidder_email', sortable: true, label: 'CONTACTO', thClass: styleColumns.thClass },
		{ key: 'competition_state_name', sortable: true, label: 'ESTADO DE <br/> LICITACIÓN', ...styleColumns },
		/* { key: 'answer', sortable: true, label: 'ESTADO DE <br/> INVITACIÓN', ...styleColumns }, */
		{ key: 'comment', sortable: false, label: 'COMENTARIO', ...styleColumns },
		{ key: 'actions', sortable: false, label: 'OPCIONES', ...styleColumns }
	];

	const perPage = ref(10);
	const totalParticipants = ref(0);
	const currentPage = ref(1);
	const perPageOptions = [10, 25, 50, 100];
	const searchQuery = ref('');
	const sortBy = ref('id');
	const isSortDirDesc = ref(false);

	const dataMeta = computed(() => {
		const localItemsCount = refParticipantsListTable.value ? refParticipantsListTable.value.localItems.length : 0;
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalParticipants.value,
		}
	});

	const refetchData = () => {
		refParticipantsListTable.value.refresh()
	};

	watch([currentPage, perPage, searchQuery], () => {
		refetchData()
	});

	const fetchCourseParticipants = (ctx, callback) => {

		const params = {
			q: searchQuery.value,
			perPage: perPage.value,
			page: currentPage.value,
			sortBy: sortBy.value,
			sortDesc: isSortDirDesc.value,
		  	tender_id: router.currentRoute.params.id
		}

		store
			.dispatch('app-participant/fetchParticipants', { id, params })
			.then(response => {
				const { total, participants } = response.data;
				callback(participants);
				totalParticipants.value = total;
			})
			.catch(() => {
				toast({
					component: ToastificationContent,
					props: {
						title: 'Error al obtener la lista de postores.',
						icon: 'AlertTriangleIcon',
						variant: 'danger',
					},
				});
			})
	};

	return {
		fetchCourseParticipants,
		tableColumns,
		perPage,
		currentPage,
		totalParticipants,
		dataMeta,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		refParticipantsListTable,

		refetchData,
	}
}