<template>
	<section>
		<b-row>
			<b-col>
				<b-card>
					<b-tabs
						content-class="mt-2"
						justified
					>
						<b-tab
							active
							title="Plantilla Económica"
						>
							<base-edit-tab-economic
								:tender-id="tenderId"
								:is-published="isPublished"
								:economic-proposal="economicProposal"
							/>
						</b-tab>

						<b-tab title="Validación de Requerimientos">
							<base-edit-tab-requirements
								:tender-id="tenderId"
								:is-published="isPublished"
								:tender-documents="tenderDocuments"
								:proposal-weightings="proposalWeightings"
								:economic-proposal="economicProposal"
							/>
						</b-tab>

						<b-tab title="Validación de Documentos">
							<base-edit-tab-documents
								:tender-id="tenderId"
								:is-published="isPublished"
							/>
						</b-tab>
					</b-tabs>
				</b-card>
			</b-col>
		</b-row>
	</section>
</template>

<script>
import BaseEditTabEconomic from "./tab-economic/BaseEditTabEconomic.vue"
import BaseEditTabRequirements from "./tab-requirements/BaseEditTabRequirements.vue"
import BaseEditTabDocuments from "./tab-documents/BaseEditTabDocuments.vue"

export default {
	components: {
		BaseEditTabEconomic,
		BaseEditTabRequirements,
		BaseEditTabDocuments
	},
	props: {
		tenderId: {
			type: Number,
			require: true
		},
		economicId: Number,
		tenderDocuments: {
			type: Array,
			default: () => [],
			require: true
		},
		proposalWeightings: {
			type: Array,
			default: () => [],
			require: true
		},
		economicProposal: {
			type: Object,
			default: () => {},
			require: true
		},
		isPublished: {
			type: Number,
			require: true
		}
	}
}
</script>