<template>
	<section class="mt-0">
		<!-- Table Container Card -->
		<b-card class="p-0">
			<b-row>
				<b-col
					class="mb-2"
					cols="12"
				>
					<!-- Table Top -->
					<b-row>
						<!-- Per Page -->
						<b-col
							cols="12"
							md="6"
							class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
						>
							<label>Mostrar</label>
							<v-select
								v-model="perPage"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:options="perPageOptions"
								:clearable="false"
								class="per-page-selector d-inline-block mx-50"
							/>
							<label>registros</label>
						</b-col>

						<!-- Search -->
						<b-col
							cols="12"
							md="6"
							class="ml-auto"
						>
							<div class="d-flex">
								<b-form-input
									v-model="searchQuery"
									class="d-inline-block mr-1"
									placeholder="Buscar ..."
									trim
								/>
								<b-button
									v-if="!isPublished"
									@click="openBidderModal()"
									variant="primary"
								>
									<span class="text-nowrap">Seleccionar Postores</span>
								</b-button>
							</div>
						</b-col>
					</b-row>
				</b-col>

				<b-col cols="12">
					<b-table
						ref="refParticipantsListTable"
						class="position-relative"
						:style="totalParticipants === 1 ? 'height: 200px' : 'height: inherit'"
						:items="fetchCourseParticipants"
						responsive
						:fields="tableColumns"
						primary-key="id"
						:sort-by.sync="sortBy"
						show-empty
						empty-text="No se encontraron registros coincidentes"
						:sort-desc.sync="isSortDirDesc"
					>
						<template #table-busy>
							<div class="text-center text-primary my-2">
								<b-spinner class="align-middle mb-1"></b-spinner>
								<div>
									<b>CARGANDO</b>
								</div>
							</div>
						</template>

						<template #head(competition_state_name)="data">
							<span v-html="data.label"></span>
						</template>

						<template #head(answer)="data">
							<span v-html="data.label"></span>
						</template>

						<!-- Column: User -->
						<template #cell(bidder_name)="data">
							<b-media vertical-align="center">
								<template #aside>
									<b-avatar
										size="32"
										:src="null"
										:text="avatarText(data.item.bidder_name)"
										variant="light-success"
									/>
								</template>
								<span class="font-weight-bold d-block text-nowrap">{{ data.item.bidder_name }}</span>
								<div class="small text-muted">{{ data.item.bidder_ruc }}</div>
							</b-media>
						</template>

						<template #cell(bidder_email)="data">
							<div class="text-nowrap">{{ data.item.bidder_email }}</div>
							<div class="small text-muted">{{ data.item.bidder_phone }}</div>
						</template>

						<template #cell(competition_state_name)="data">
							<div class="text-nowrap">
								<b-badge
									:variant="`light-${ data.item.competition_state_name ? data.item.competition_state_color : 'secondary' }`"
								>{{ data.item.competition_state_name || '-' }}</b-badge>
							</div>
						</template>

						<template #cell(answer)="data">
							<div class="text-nowrap">
								<b-badge
									:variant="`light-${ data.item.answer === 1 ? 'success' : data.item.answer === 0 ? 'danger' : 'secondary' }`"
								>{{ data.item.answer === 1 ? 'ACEPTADA' : data.item.answer === 0 ? 'RECHAZADA' : '-' }}</b-badge>
							</div>
						</template>

						<template #cell(comment)="data">
							<div class="text-nowrap">
								<b-button
									v-ripple.400="'rgba(113, 102, 240, 0.15)'"
									variant="outline-primary"
									class="btn-icon rounded-circle"
									@click="openCommentModal(data.item)"
								>
									<feather-icon icon="MessageSquareIcon" />
								</b-button>
							</div>
						</template>

						<!-- Column: Actions -->
						<template #cell(actions)="data">
							<b-dropdown
								variant="link"
								no-caret
								:right="$store.state.appConfig.isRTL"
							>
								<template #button-content>
									<feather-icon
										icon="MoreVerticalIcon"
										size="16"
										class="align-middle text-body"
									/>
								</template>

								<b-dropdown-item
									v-if="isPublished == 1"
									@click="resendInvitation(data.item.id)"
								>
									<feather-icon icon="MailIcon" />
									<span class="align-middle ml-50">Reenviar Invitación</span>
								</b-dropdown-item>

								<b-dropdown-item
									v-if="isPublished == 0"
									@click="deleteParticipant(data.item.id)"
								>
									<feather-icon icon="TrashIcon" />
									<span class="align-middle ml-50">Eliminar</span>
								</b-dropdown-item>
							</b-dropdown>
						</template>
					</b-table>
				</b-col>

				<b-col
					class="mx-2 mb-2"
					cols="12"
				>
					<b-row>
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-start"
						>
							<span
								class="text-muted"
							>Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
						</b-col>
						<!-- Pagination -->
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-end"
						>
							<b-pagination
								v-model="currentPage"
								:total-rows="totalParticipants"
								:per-page="perPage"
								first-number
								last-number
								class="mb-0 mt-1 mt-sm-0"
								prev-class="prev-item"
								next-class="next-item"
							>
								<template #prev-text>
									<feather-icon
										icon="ChevronLeftIcon"
										size="18"
									/>
								</template>
								<template #next-text>
									<feather-icon
										icon="ChevronRightIcon"
										size="18"
									/>
								</template>
							</b-pagination>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</b-card>

		<!-- SELECT BIDDERS PARTICIPANTS -->
		<b-modal
			ref="bidderModal"
			size="xl"
			centered
			title-tag="div"
			header-bg-variant="primary"
			header-text-variant="white"
			title="SELECCIÓN DE POSTORES"
			@hide="closeBidderModal"
			no-close-on-backdrop
			no-close-on-esc
			hide-header-close
		>
			<b-alert
				variant="primary"
				show
			>
				<div class="alert-body">
					<ul class="mb-0">
						<!--  <li v-if="isPublished == 0">
                            La licitación debe ser <strong>PUBLICADA</strong> para que pueda <strong>CONFIRMAR LA SELECCIÓN</strong>.
						</li>-->
						<li>Seleccione los postores que participarán en la licitación</li>
						<li v-if="noBidders">
							No existen postores, puede agregarlos desde
							<b-link :to="{name: 'bidders-list'}">
								<strong>Lista de Postores</strong>
							</b-link>.
						</li>
					</ul>
				</div>
			</b-alert>

			<b-card-text class="my-2">
				<!-- sorting  -->
				<b-form-group
					label="Ordenar"
					label-size="sm"
					label-align-sm="left"
					label-cols-sm="2"
					label-for="sortBySelect"
					class="mb-md-0"
				>
					<b-input-group size="sm">
						<b-form-select
							id="sortBySelect"
							v-model="sortByModal"
							:options="sortOptions"
						>
							<template #first>
								<option value>Ninguno</option>
							</template>
						</b-form-select>
						<b-form-select
							v-model="sortDescModal"
							size="sm"
							:disabled="!sortByModal"
						>
							<option :value="false">Asc</option>
							<option :value="true">Desc</option>
						</b-form-select>
					</b-input-group>
				</b-form-group>

				<!-- filter -->
				<b-form-group
					label="Fitrar"
					label-cols-sm="2"
					label-align-sm="left"
					label-size="sm"
					label-for="filterInput"
					class="mb-0 mt-1"
				>
					<b-input-group size="sm">
						<b-form-input
							id="filterInput"
							v-model="filterModal"
							type="search"
							placeholder="Buscar ..."
						/>
						<b-input-group-append>
							<b-button
								:disabled="!filterModal"
								@click="filterModal = ''"
							>Limpiar</b-button>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>

				<b-row
					align-v="center"
					align-h="between"
				>
					<b-col
						cols="3"
						class="my-1"
					>
						<b-form-group class="mb-0">
							<label class="d-inline-block text-sm-left mr-50">Por página</label>
							<b-form-select
								id="perPageSelect"
								v-model="perPageModal"
								size="sm"
								:options="pageOptionsModal"
								class="w-50"
							/>
						</b-form-group>
					</b-col>
				</b-row>

				<!-- table -->
				<b-table
					ref="biddersTable"
					class="position-relative"
					:per-page="perPageModal"
					:current-page="currentPageModal"
					:items="biddersItems"
					:fields="biddersFields"
					:sort-by.sync="sortByModal"
					:sort-desc.sync="sortDescModal"
					:sort-direction="sortDirectionModal"
					:filter="filterModal"
					:filter-included-fields="filterOnModal"
					@filtered="onFiltered"
					striped
					hover
					responsive
					show-empty
					empty-text="No se encontraron registros coincidentes"
				>
					<template #head(approval_state_name)="data">
						<span v-html="data.label"></span>
					</template>

					<template #head(tender_count)="data">
						<span v-html="data.label"></span>
					</template>

					<template #cell(#)="row">
						<b-form-checkbox
							v-model="selectedBidders"
							:value="row.item"
							class="p-0"
						></b-form-checkbox>
					</template>

					<template #cell(bidder_name)="data">
						<span class="font-weight-bold d-block text-nowrap">{{ data.item.bidder_name }}</span>
						<div class="small text-muted">{{ data.item.ruc }}</div>
					</template>

					<template #cell(user_email)="data">
						<div class="text-nowrap">{{ data.item.user_email }}</div>
						<div class="small text-muted">{{ data.item.user_phone }}</div>
					</template>

					<template #cell(business_area_name)="data">
						<div class="text-nowrap">{{ data.item.business_area_name }}</div>
					</template>

					<template #cell(approval_state_name)="data">
						<div class="text-nowrap">
							<b-badge
								:variant="`light-${data.item.approval_state_color}`"
							>{{ data.item.approval_state_name }}</b-badge>
						</div>
					</template>
				</b-table>

				<!-- pagination -->
				<div>
					<b-pagination
						v-model="currentPageModal"
						:total-rows="totalRowsModal"
						:per-page="perPageModal"
						first-number
						last-number
						prev-class="prev-item"
						next-class="next-item"
						class="mb-0"
					>
						<template #prev-text>
							<feather-icon
								icon="ChevronLeftIcon"
								size="18"
							/>
						</template>
						<template #next-text>
							<feather-icon
								icon="ChevronRightIcon"
								size="18"
							/>
						</template>
					</b-pagination>
				</div>
			</b-card-text>

			<template #modal-footer="{ cancel }">
				<!-- v-if="isPublished == 1" -->
				<b-button
					v-if="!isPublished"
					@click="addParticipants()"
					variant="primary"
					:disabled="processing"
				>
					<div
						class="d-flex align-items-center justify-content-center"
						v-if="processing"
					>
						<b-spinner small />
						<b class="ml-1">PROCESANDO</b>
					</div>
					<b v-else>CONFIRMAR SELECCIÓN</b>
				</b-button>

				<b-button
					v-if="!processing"
					v-ripple.400="'rgba(186, 191, 199, 0.15)'"
					type="button"
					variant="outline-secondary"
					class="ml-2"
					@click="cancel"
				>CANCELAR</b-button>
			</template>
		</b-modal>

		<!-- modal comment-->
		<b-modal
			ref="commentModal"
			title-tag="div"
			header-bg-variant="primary"
			header-text-variant="white"
			centered
			title="COMENTARIO SOBRE EL POSTOR"
			no-close-on-backdrop
			no-close-on-esc
			hide-header-close
		>
			<b-form>
				<b-col
					cols="12"
					class="my-2"
				>
					<b-form-group>
						<label for="comment">
							<strong>Comentario</strong>
						</label>
						<b-form-textarea
							id="comment"
							v-model="commentInput"
							placeholder="Comente aquí ..."
							:disabled="!!isPublished"
							:readonly="!!isPublished"
							rows="4"
						/>
					</b-form-group>
				</b-col>
			</b-form>

			<template #modal-footer="{ cancel }">
				<b-button
					v-if="!isPublished"
					@click="saveComment()"
					variant="primary"
				>GUARDAR</b-button>

				<b-button
					v-ripple.400="'rgba(186, 191, 199, 0.15)'"
					type="button"
					variant="outline-secondary"
					class="ml-2"
					@click="cancel"
				>CERRAR</b-button>
			</template>
		</b-modal>
	</section>
</template>

<script>
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import { VBModal } from "bootstrap-vue"
import vSelect from "vue-select"
import VueSweetalert2 from "vue-sweetalert2"
import Ripple from "vue-ripple-directive"
import { ref, onUnmounted, computed } from "@vue/composition-api"
import useTenderEditTabParticipants from "./useTenderEditTabParticipants"
import participantStoreModule from "./participantStoreModule"
import { avatarText } from "@core/utils/filter"
import useNotifications from "@notifications"
import moment from "moment"
import store from "@/store"
import axios from "@axios"
import router from "@/router"
import Vue from "vue"
import "animate.css"

Vue.use(VueSweetalert2)

export default {
	components: {
		vSelect,

		BButtonLoading
	},
	directives: {
		"b-modal": VBModal,
		Ripple
	},
	props: {
		tenderId: {
			type: Number,
			required: true
		},
		isPublished: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			moment
		}
	},
	setup(props) {
		// Use toast
		const { toastNotification, swalNotification } = useNotifications()

		const PARTICIPANT_APP_STORE_MODULE_NAME = "app-participant"

		// Register module
		if (!store.hasModule(PARTICIPANT_APP_STORE_MODULE_NAME))
			store.registerModule(
				PARTICIPANT_APP_STORE_MODULE_NAME,
				participantStoreModule
			)

		// UnRegister on leave
		onUnmounted(() => {
			if (store.hasModule(PARTICIPANT_APP_STORE_MODULE_NAME))
				store.unregisterModule(PARTICIPANT_APP_STORE_MODULE_NAME)
		})

		const processing = ref(false)
		const showModal = ref(false)

		const noBidders = ref(false)

		const bidderModal = ref(null)
		const commentModal = ref(null)
		const commentInput = ref(null)
		const invitationId = ref(null)
		const biddersItems = ref([])
		const biddersFields = ref([])

		const totalRowsModal = ref(1)
		const currentPageModal = ref(1)
		const perPageModal = ref(10)
		const pageOptionsModal = ref([5, 10, 15, 20])
		const sortByModal = ref("")
		const sortDescModal = ref(false)
		const sortDirectionModal = ref("asc")
		const filterModal = ref(null)
		const filterOnModal = ref([])

		const selectedBidders = ref([])

		const styleColumns = {
			thClass: "text-center",
			tdClass: "text-center"
		}

		biddersFields.value = [
			{
				label: "#",
				key: "#",
				...styleColumns
			},
			{
				label: "postor",
				sortable: true,
				key: "bidder_name",
				thClass: styleColumns.thClass
			},
			{
				label: "CONTACTO",
				sortable: true,
				key: "user_email",
				thClass: styleColumns.thClass
			},
			{
				label: "RUBRO",
				sortable: true,
				key: "business_area_name",
				...styleColumns
			},
			{
				label: "ESTADO <br/> DE HOMOLOGACIÓN",
				sortable: true,
				key: "approval_state_name",
				...styleColumns
			},
			{
				label: "N° LICITACIONES <br/> PARTICIPADAS",
				sortable: true,
				key: "tender_count",
				...styleColumns
			}
		]

		const sortOptions = computed(() => {
			return biddersFields.value
				.filter((f) => f.sortable)
				.map((f) => ({ text: f.label, value: f.key }))
		})

		const onFiltered = (filteredItems) => {
			totalRowsModal.value = filteredItems.length
			currentPage.value = 1
		}

		const getBidders = async () => {
			noBidders.value = false
			const { data } = await axios.get("/tenders/select/bidders")

			if (data.total === 0) {
				noBidders.value = true
				return
			}

			biddersItems.value = [...data.bidders]
		}

		const openCommentModal = ({ id, comment }) => {
			invitationId.value = id
			commentInput.value = comment

			commentModal.value.show()
		}

		const openBidderModal = async () => {
			processing.value = false
			selectedBidders.value = []

			await getBidders()

			totalRowsModal.value = biddersItems.value.length
			currentPageModal.value = 1
			sortByModal.value = ""
			sortDescModal.value = false
			sortDirectionModal.value = "asc"
			filterModal.value = null

			bidderModal.value.show()
		}

		const closeBidderModal = () => bidderModal.value.hide()

		const addParticipants = () => {
			if (selectedBidders.value.length === 0) {
				const message = "Debe seleccionar al menos un participante."
				toastNotification(message, false)
				return
			}

			processing.value = true

			const bidders = JSON.stringify(
				selectedBidders.value.map((item) => ({ id: item.id }))
			)

			const payload = {
				tender_id: router.currentRoute.params.id,
				published: false,
				bidders
			}

			store
				.dispatch("app-participant/addParticipants", { payload })
				.then((response) => {
					refetchData()
					toastNotification(response.data.message)
					closeBidderModal()
				})
				.catch((error) => {
					const message =
						error.response.data.message || "Error en la selección de postores."
					toastNotification(message, false)
				})
				.finally(() => {
					processing.value = false
				})
		}

		const saveComment = () => {
			const payload = {
				comment: commentInput.value,
				published: false,
				tender_id: router.currentRoute.params.id
			}

			store
				.dispatch("app-participant/saveComment", {
					id: invitationId.value,
					payload
				})
				.then((response) => {
					toastNotification(response.data.message)
					refetchData()
				})
				.catch((error) => {
					toastNotification(error.response.data.message, false)
				})
				.finally((_) => {
					invitationId.value = null
					commentInput.value = null
					commentModal.value.hide()
				})
		}

		const resendInvitation = (id) => {
			const payload = {
				published: false,
				tender_id: router.currentRoute.params.id
			}

			store
				.dispatch("app-participant/resendInvitation", { id, payload })
				.then((response) => {
					toastNotification(response.data.message)
					refetchData()
				})
				.catch((error) => {
					toastNotification(error.response.data.message, false)
				})
		}

		const deleteParticipant = (id) => {
			Vue.swal({
				title: "¿Está seguro(a) de eliminar al postor?",
				html: "<small>¡Si no lo está, puede cancelar la acción!</small>",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sí, eliminar!",
				cancelButtonText: "Cancelar",
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1"
				},
				showClass: {
					popup: "animate__animated animate__tada"
				},
				buttonsStyling: false
			}).then((result) => {
				if (result.value) {
					const payload = {
						tender_id: router.currentRoute.params.id
					}

					store
						.dispatch("app-participant/deleteParticipant", { id, payload })
						.then((response) => {
							refetchData()
							swalNotification(response.data.message)
						})
						.catch((error) => {
							const message =
								error.response.data.message ||
								"Error al eliminar al participante."
							swalNotification(message, false)
						})
				}
			})
		}

		const {
			fetchCourseParticipants,
			tableColumns,
			perPage,
			currentPage,
			totalParticipants,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refParticipantsListTable,
			refetchData
		} = useTenderEditTabParticipants(props.tenderId)

		return {
			fetchCourseParticipants,
			tableColumns,
			perPage,
			currentPage,
			totalParticipants,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refParticipantsListTable,
			refetchData,

			avatarText,
			resendInvitation,

			showModal,
			processing,
			noBidders,

			bidderModal,
			biddersItems,
			biddersFields,

			totalRowsModal,
			currentPageModal,
			perPageModal,
			pageOptionsModal,
			sortByModal,
			sortDescModal,
			sortDirectionModal,
			filterModal,
			filterOnModal,

			selectedBidders,
			sortOptions,
			onFiltered,

			closeBidderModal,
			openBidderModal,

			addParticipants,
			deleteParticipant,

			commentInput,
			openCommentModal,
			commentModal,
			saveComment
		}
	}
}
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>