<template>
	<section>
		<b-form>
			<b-row>
				<b-col
					cols="12"
					md="6"
					class="mb-1"
				>
					<label>
						<strong>DOCUMENTOS GENERALES</strong>
					</label>
					<b-button-upload-file
						:tender-id="tenderId"
						:new-extensions="true"
						:is-published="isPublished"
						:tender-props="{ isGeneral: true }"
						:modal-props="{ title: 'DOCUMENTOS GENERALES' }"
						:button-props="{ icon: 'PaperclipIcon', text: !!isPublished ?  'VER DOCUMENTOS' : 'CARGAR DOCUMENTOS', block : true }"
					/>
				</b-col>
				<b-col
					cols="12"
					md="6"
					class="mb-1"
				>
					<label>
						<strong>DOCUMENTOS ESPECIFICOS</strong>
					</label>
					<b-button-upload-file
						:tender-id="tenderId"
						:tender-props="{ isSpecific: true }"
						:modal-props="{ title: 'DOCUMENTOS ESPECÍFICOS' }"
						:button-props="{ icon: 'PaperclipIcon', text: 'VER DOCUMENTOS', block : true }"
					/>
				</b-col>
			</b-row>
		</b-form>
	</section>
</template>

<script>
import BButtonUploadFile from "@core/components/b-button-upload-file/BButtonUploadFile.vue"
export default {
	components: {
		BButtonUploadFile
	},
	props: {
		tenderId: {
			type: Number,
			required: true
		},
		isPublished: {
			type: Number,
			require: true
		}
	}
}
</script>