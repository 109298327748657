<template>
	<validation-observer ref="refForm">
		<b-form
			@submit.prevent="onSubmit"
			@reset.prevent="resetForm"
		>
			<b-row>
				<b-col md="12">
					<b-card
						footer-tag="footer"
						class="mb-0"
					>
						<b-row>
							<b-col
								cols="12"
								class="my-1"
							>
								<b-alert
									variant="info"
									show
								>
									<h6 class="alert-heading">
										<feather-icon
											icon="InfoIcon"
											class="mr-50"
										/>
										<span
											class="small"
										>La ejecución del servicio se dará acabo en la(s) unidad(es) seleccionada(s)</span>
									</h6>
								</b-alert>
							</b-col>

							<!-- TENDER TITLE -->
							<b-col
								cols="12"
								class="mb-2"
							>
								<label for="name">
									<strong>OBJETO DEL CONTRATO</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									rules="required"
									name="objeto del contrato"
								>
									<b-form-input
										id="name"
										v-model="formData.title"
										placeholder="Ingrese el objeto del contrato ..."
										:state="errors.length > 0 ? false : null"
										:disabled="!!isPublished"
										:readonly="!!isPublished"
										trim
									/>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<!-- CONTRACT TYPE -->
							<b-col
								cols="12"
								md="6"
								lg="7"
								class="mb-2"
							>
								<label for="contractType">
									<strong>TIPO DE CONTRATO</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									rules="required"
									name="tipo de contrato"
								>
									<v-select
										id="contractType"
										v-model="formData.contract_type"
										:state="errors.length > 0 ? false : null"
										:options="options.contractTypes"
										placeholder="Seleccione"
										:disabled="!!isPublished"
										:readonly="!!isPublished"
									>
										<template #option="{ label, description, code }">
											{{ label }}
											<br />
											<cite>{{ description }} | Código: {{ code }}</cite>
										</template>
									</v-select>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<!-- LOCATION -->
							<b-col
								cols="12"
								md="6"
								lg="5"
								class="mb-2"
							>
								<label for="location">
									<strong>EJECUCIÓN DE SERVICIO</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									rules="required"
									name="rubro"
								>
									<v-select
										id="location"
										v-model="formData.locations"
										:state="errors.length > 0 ? false : null"
										multiple
										:options="options.locations"
										:disabled="!!isPublished"
										:readonly="!!isPublished"
										placeholder="Seleccione"
									/>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<!-- DESCRIPTION -->
							<b-col
								cols="12"
								class="mb-2"
							>
								<label for="description">
									<strong>DESCRIPCIÓN</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									rules="required"
									name="descripción"
								>
									<b-form-textarea
										id="description"
										v-model="formData.description"
										placeholder="Ingrese los detalles ..."
										rows="5"
										:state="(errors.length > 0 ? false:null)"
										:disabled="!!isPublished"
										:readonly="!!isPublished"
										trim
									></b-form-textarea>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<!-- VISIT -->
							<b-col
								cols="12"
								class="mb-2"
							>
								<b-form-checkbox
									:checked="formData.enabled_visit === 1"
									@change="handleCheckboxChange"
									:disabled="!!isPublished"
								>HABILITAR VISITA</b-form-checkbox>
							</b-col>

							<!-- VISIT DOCUMENTS -->
							<b-col
								cols="12"
								v-if="formData.enabled_visit"
							>
								<b-input-group class="mb-1">
									<b-form-input
										v-model="newDocument"
										placeholder="Ingrese nuevo documento y presione ENTER o click al botón AGREGAR"
										:disabled="!!isPublished"
										@keydown.enter.prevent="addDocument"
										trim
									/>
									<b-input-group-append>
										<b-button
											:disabled="!!isPublished"
											@click="addDocument"
											variant="outline-primary"
										>Agregar</b-button>
									</b-input-group-append>
								</b-input-group>

								<label
									for="visitDocuments"
									class="mb-1"
								>
									<strong>DOCUMENTOS REQUERIDOS PARA LA VISITA</strong>
								</label>
								<div
									class="d-flex align-items-center mb-1"
									v-for="(document, index) in formData.documents"
									:key="index"
								>
									<b-form-checkbox
										name="documents"
										:checked="document.is_required === 1"
										@change="toggleRequired(document)"
										:disabled="!!isPublished"
									>{{ document.name }}</b-form-checkbox>
									<div
										class="d-flex align-items-center text-nowrap"
										v-if="document.is_new"
									>
										<feather-icon
											v-if="!isPublished"
											:id="`document-${index}`"
											icon="XIcon"
											class="cursor-pointer text-danger"
											style="margin-left: 10px"
											size="16"
											@click.stop="removeDocument(index)"
										/>
										<b-tooltip
											variant="danger"
											placement="right"
											triggers="hover"
											title="Eliminar"
											class="cursor-pointer"
											:target="`document-${index}`"
										/>
									</div>
								</div>
							</b-col>
						</b-row>

						<template #footer>
							<b-col class="d-flex flex-column flex-sm-row justify-content-sm-end">
								<!-- BUTTON SUBMIT -->
								<b-button-loading
									v-if="!isPublished"
									text="GUARDAR CAMBIOS"
									type="submit"
									class="order-sm-2 order-1 ml-sm-2 ml-0"
									:processing="processing"
								/>
							</b-col>
						</template>
					</b-card>
				</b-col>
			</b-row>
		</b-form>
	</validation-observer>
</template>

<script>
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import { ref, onMounted } from "@vue/composition-api"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { required, email, integer, min } from "@validations"
import useNotifications from "@notifications"
import Ripple from "vue-ripple-directive"
import { VBTooltip } from "bootstrap-vue"
import vSelect from "vue-select"
import store from "@/store"
import axios from "@axios"

export default {
	components: {
		ValidationProvider,
		ValidationObserver,
		BButtonLoading,

		required,
		email,
		integer,
		min,

		vSelect
	},
	directives: {
		"b-tooltip": VBTooltip,
		Ripple
	},
	props: {
		tenderData: {
			type: Object,
			required: true
		},
		isPublished: {
			type: Number,
			required: true
		}
	},
	setup(props, { emit }) {
		// USE TOAST
		const { toastNotification } = useNotifications()

		// REFS
		const tenderData = ref(props.tenderData)
		const refForm = ref(null)
		const options = ref({})
		const processing = ref(false)
		const newDocument = ref(null)
		const deletedDocuments = ref([])
		const formData = ref({
			title: null,
			description: null,
			enabled_visit: 0,
			is_published: 0,
			documents: [],
			locations: []
		})

		onMounted(async () => {
			await getSelectFilters()
			setData()
		})

		const getSelectFilters = async () => {
			const response1 = await axios.get("/selectors/contract-types")
			const response2 = await axios.get("/selectors/locations")
			options.value = {
				...response1.data,
				...response2.data
			}
		}

		const setData = () => {
			const contract_type = options.value.contractTypes.find(
				(item) => item.value === tenderData.value.contract_type_id
			)

			const locationIds = tenderData.value.locations.map((item) => item.value)

			const locations = options.value.locations.filter((item) =>
				locationIds.includes(item.value)
			)

			formData.value = {
				title: tenderData.value.title,
				description: tenderData.value.description,
				is_published: tenderData.value.is_published,
				enabled_visit: tenderData.value.enabled_visit,
				documents: tenderData.value.visit_documents,
				contract_type,
				locations
			}
		}

		const addDocument = () => {
			if (newDocument.value) {
				formData.value.documents.push({
					name: newDocument.value.toUpperCase(),
					is_required: 1,
					is_new: 1
				})
			}

			newDocument.value = null
		}

		const removeDocument = (index) => {
			const document = formData.value.documents[index]
			if (document.id) {
				deletedDocuments.value.push(document)
			}

			formData.value.documents.splice(index, 1)
		}

		const toggleRequired = (document) => {
			document.is_required = document.is_required === 1 ? 0 : 1
		}

		const handleCheckboxChange = (checked) => {
			if (checked) {
				formData.value.enabled_visit = 1
			} else {
				formData.value.enabled_visit = 0
				formData.value.documents = formData.value.documents.map((item) => ({
					...item,
					is_required: 0
				}))
			}
		}

		const onSubmit = async () => {
			processing.value = true

			const isValid = await refForm.value.validate()

			let error = false
			let message = null

			if (!isValid) {
				error = true
				message = "Complete los campos requeridos"
			} else if (!formData.value.locations.length) {
				message = "Debe seleccionar al menos un lugar de ejecuición de servicio"
				error = true
			} else if (formData.value.enabled_visit) {
				const haveDocsRequired = formData.value.documents.some(
					(item) => item.is_required === 1
				)
				if (!haveDocsRequired) {
					message = "Debe seleccionar al menos un documento para la visita"
					error = true
				}
			}

			if (error) {
				processing.value = false
				toastNotification(message, false)
				return
			}

			const locations = formData.value.locations.map((item) => item.value)

			const payload = {
				locations,
				title: formData.value.title,
				contract_type_id: formData.value.contract_type.value,
				description: formData.value.description,
				enabled_visit: formData.value.enabled_visit,
				selectedDocuments: formData.value.documents,
				deletedDocuments: deletedDocuments.value,
				published: false,
				fromParams: true
			}

			store
				.dispatch("app-tender/updateTender", {
					id: tenderData.value.id,
					payload
				})
				.then((response) => {
					const data = {
						title: payload.title,
						locations: formData.value.locations,
						contract_name: formData.value.contract_type.label,
						description: payload.description,
						enabled_visit: payload.enabled_visit
					}

					emit("update-information", data)
					toastNotification(response.data.message)
				})
				.catch((error) => {
					const message =
						error.response.data.message || "Error al actualizar la información"
					toastNotification(message, false)
				})
				.finally(() => (processing.value = false))
		}

		return {
			// DATA
			processing,
			formData,
			options,

			// REFS
			refForm,
			newDocument,

			// METHODS
			setData,
			addDocument,
			removeDocument,
			toggleRequired,
			handleCheckboxChange,
			onSubmit
		}
	}
}
</script>

<style lang="scss" scoped>
.description-content {
	min-height: 150px;
	overflow-y: auto;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>