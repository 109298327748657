
<template>
	<component :is="'div'">
		<!-- Alert: No item found -->
		<b-alert
			variant="danger"
			:show="tenderData === undefined"
		>
			<h4 class="alert-heading">Error al obtener los datos de la licitación</h4>
			<div class="alert-body">
				No se encontró ninguna licitación con este id. Verificar en la
				<b-link
					class="alert-link"
					:to="{ name: 'tenders-list'}"
				>Lista de licitaciones</b-link>para buscar otra licitación.
			</div>
		</b-alert>

		<b-tabs
			pills
			v-model="activeTab"
			v-if="tenderData"
		>
			<!-- Tab: General Information -->
			<b-tab name="information">
				<template #title>
					<feather-icon
						icon="InfoIcon"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Información general</span>
				</template>

				<tender-edit-tab-general-information
					class="mt-2 pt-75"
					:is-published="tenderData.is_published"
					:tender-data="tenderData"
					@update-information="updateInformation"
				/>
			</b-tab>

			<!-- Tab: Bidders -->
			<b-tab name="bidders">
				<template #title>
					<feather-icon
						icon="UsersIcon"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Validación Postores</span>
				</template>

				<tender-edit-tab-participants
					class="mt-2 pt-75"
					:tender-id="tenderData.id"
					:is-published="tenderData.is_published"
				/>
			</b-tab>

			<!-- Tab: Validations -->
			<b-tab name="validations">
				<template #title>
					<feather-icon
						icon="ArchiveIcon"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Validación Bases</span>
				</template>

				<tender-edit-tab-bases
					class="mt-2 pt-75"
					:tender-id="tenderData.id"
					:is-published="tenderData.is_published"
					:economic-id="tenderData.economic_proposal_id"
					:tender-documents="tenderData.tender_documents"
					:proposal-weightings="tenderData.proposal_weightings"
					:economic-proposal="tenderData.economic_proposal"
				/>
			</b-tab>

			<!-- Tab: Schedules -->
			<b-tab name="schedules">
				<template #title>
					<feather-icon
						icon="CalendarIcon"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Cronograma</span>
				</template>

				<tender-edit-tab-schedules
					class="mt-2 pt-75"
					:tender-id="tenderData.id"
					:schedule-data="schedules"
					:is-published="tenderData.is_published"
					@update-schedule="updateSchedule"
				/>
			</b-tab>

			<!-- Tab: Invitations -->
			<b-tab name="invitations">
				<template #title>
					<feather-icon
						icon="BookmarkIcon"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Invitación</span>
				</template>

				<tender-edit-tab-invitations
					class="mt-2 pt-75"
					:tender-id="tenderData.id"
					:tender-data="tenderData"
					:schedule-data="schedules.filter(item => item.tender_stage_enabled == 1)"
					:is-published="tenderData.is_published"
				/>
			</b-tab>
		</b-tabs>
	</component>
</template>

<script>
import { formatDateToString } from "@core/utils/filter"
import { Stages } from "@core/utils/data"
import TenderEditTabGeneralInformation from "./general-information/TenderEditTabGeneralInformation.vue"
import TenderEditTabParticipants from "./participants/TenderEditTabParticipants.vue"
import TenderEditTabSchedules from "./schedules/TenderEditTabSchedules.vue"
import TenderEditTabBases from "./bases/BaseEdit.vue"
import TenderEditTabInvitations from "./invitations/TenderEditTabInvitations.vue"
import tenderStoreModule from "../tenderStoreModule"
import { ref, onUnmounted, onMounted, computed } from "@vue/composition-api"
import useNotifications from "@notifications"
import router from "@/router"
import store from "@/store"
import moment from "moment"

export default {
	components: {
		TenderEditTabGeneralInformation,
		TenderEditTabParticipants,
		TenderEditTabBases,
		TenderEditTabSchedules,
		TenderEditTabInvitations
	},
	/* computed: {
		activeTab: {
			set(val) {
				let query = { ...router.currentRoute.query }
				query.tab = val
				router.replace({ query: query })
			},
			get() {
				return parseInt(router.currentRoute.query.tab)
			}
		}
	}, */
	setup() {
		const { VISITA } = Stages
		const { toastNotification } = useNotifications()

		const activeTab = computed({
			set(val) {
				let query = { ...router.currentRoute.query }
				query.tab = val
				router.replace({ query: query })
			},
			get() {
				return parseInt(router.currentRoute.query.tab)
			}
		})
		// REFS
		const tenderData = ref(null)
		const schedules = ref([])

		const TENDER_APP_STORE_MODULE_NAME = "app-tender"

		// REGISTER MODULE
		if (!store.hasModule(TENDER_APP_STORE_MODULE_NAME))
			store.registerModule(TENDER_APP_STORE_MODULE_NAME, tenderStoreModule)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(TENDER_APP_STORE_MODULE_NAME))
				store.unregisterModule(TENDER_APP_STORE_MODULE_NAME)
		})

		store
			.dispatch("app-tender/fetchTender", {
				id: router.currentRoute.params.id
			})
			.then((response) => {
				tenderData.value = response.data.tender

				if (!tenderData.value.enabled_visit) {
					schedules.value = response.data.tender.schedules.filter(
						(item) => item.tender_stage_id !== VISITA
					)
				} else {
					schedules.value = response.data.tender.schedules
				}
			})
			.catch((error) => {
				if (error.response.status === 404) {
					tenderData.value = undefined
				}
				toastNotification(error.response.data.message, false)
				/* 	router.push({ name: "tenders-list" }) */
			})

		const updateInformation = (data) => {
			tenderData.value.title = data.title
			tenderData.value.description = data.description
			tenderData.value.locations = data.locations
			tenderData.value.contract_name = data.contract_name

			if (!data.enabled_visit) {
				schedules.value = schedules.value.filter(
					(item) => item.tender_stage_id !== VISITA
				)
			} else {
				schedules.value = [...tenderData.value.schedules]
			}
		}

		const updateSchedule = ({ index, payload }) => {
			schedules.value[index].days = payload.days
			schedules.value[index].actual_days = payload.actual_days
			schedules.value[index].days_difference = payload.days_difference
			schedules.value[index].start_date = formatDateToString(payload.start_date)
			schedules.value[index].end_date = formatDateToString(payload.end_date)
			schedules.value[index].actual_start_date = formatDateToString(
				payload.actual_start_date
			)
			schedules.value[index].actual_end_date = formatDateToString(
				payload.actual_end_date
			)
		}

		return {
			moment,

			activeTab,
			tenderData,
			schedules,

			updateInformation,
			updateSchedule
		}
	}
}
</script>