<template>
	<b-sidebar
		id="add-new-consultation-sidebar"
		:visible="isAddNewConsultationSidebarActive"
		bg-variant="white"
		sidebar-class="sidebar-lg"
		shadow
		backdrop
		no-header
		right
		@hidden="resetForm"
		@change="(val) => $emit('update:is-add-new-consultation-sidebar-active', val)"
	>
		<template #default="{ hide }">
			<!-- Header -->
			<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
				<h5 class="mb-0">
					<strong>AGREGAR CONSULTAS</strong>
				</h5>

				<feather-icon
					class="ml-1 cursor-pointer"
					icon="XIcon"
					size="16"
					@click="hide"
				/>
			</div>

			<validation-observer
				#default="{ handleSubmit }"
				ref="refFormObserver"
			>
				<b-form
					class="p-2"
					@submit.prevent="handleSubmit(onSubmit)"
					@reset.prevent="resetForm"
				>
					<div
						v-for="(stage, index) in stageData"
						:key="index"
					>
						<b-form-group
							label="ETAPA"
							label-for="stage"
						>
							<b-form-input
								id="stage"
								:value="stage.name"
								disabled
								readonly
							/>
						</b-form-group>

						<validation-provider
							#default="{ errors }"
							:name="`Fecha Inicial Programada ${index + 1}`"
							rules="required"
						>
							<b-form-group
								label="FECHA INICIAL PROGRAMADA"
								:label-for="`start_date_${index}`"
							>
								<flat-pickr
									:id="`start_date_${index}`"
									class="form-control"
									:config="onlyDateConfig"
									v-model="stage.start_date"
									:state="errors.length > 0 ? false:null"
									placeholder="DD-MM-YYYY"
									@input="updateEndDate(index)"
									trim
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</b-form-group>
						</validation-provider>

						<validation-provider
							#default="{ errors }"
							:name="`Fecha Inicial Real ${index + 1}`"
							rules="required"
						>
							<b-form-group
								label="FECHA INICIAL REAL"
								:label-for="`actual_start_date_${index}`"
							>
								<flat-pickr
									:id="`actual_start_date_${index}`"
									class="form-control"
									:config="onlyDateConfig"
									v-model="stage.actual_start_date"
									:state="errors.length > 0 ? false:null"
									placeholder="DD-MM-YYYY"
									@input="updateEndDate(index)"
									trim
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</b-form-group>
						</validation-provider>

						<validation-provider
							#default="{ errors }"
							rules="required|integer|min:1"
							:name="`Días por etapa ${index + 1}`"
						>
							<b-form-group
								label="DÍAS POR ETAPAL"
								:label-for="`days_${index}`"
							>
								<b-form-input
									:id="`days_${index}`"
									v-model="stage.days"
									:state="errors.length > 0 ? false : null"
									placeholder="-"
									@input="updateEndDate(index)"
									trim
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</b-form-group>
						</validation-provider>

						<b-form-group
							label="FECHA FINAL"
							:label-for="`end_date_${index}`"
						>
							<b-form-input
								:id="`end_date_${index}`"
								placeholder="DD-MM-YYYY"
								:value="stage.end_date"
								disabled
								readonly
							/>
						</b-form-group>
					</div>

					<div class="d-flex mt-2">
						<b-button
							v-ripple.400="'rgba(255, 255, 255, 0.15)'"
							variant="primary"
							class="mr-2"
							type="submit"
						>AGREGAR</b-button>
						<b-button
							v-ripple.400="'rgba(186, 191, 199, 0.15)'"
							type="button"
							variant="outline-secondary"
							@click="hide"
						>CANCELAR</b-button>
					</div>
				</b-form>
			</validation-observer>
		</template>
	</b-sidebar>
</template>

<script>
import useNotifications from "@notifications"
import { BSidebar, BFormDatepicker } from "bootstrap-vue"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { ref, onBeforeMount } from "@vue/composition-api"
import { required, integer, min } from "@validations"
import flatPickr from "vue-flatpickr-component"
import { Spanish } from "flatpickr/dist/l10n/es"
import Ripple from "vue-ripple-directive"
import vSelect from "vue-select"
import store from "@/store"
import axios from "@axios"
import moment from "moment"

export default {
	components: {
		BSidebar,
		BFormDatepicker,

		ValidationProvider,
		ValidationObserver,

		flatPickr,
		required,
		integer,
		min,
		vSelect
	},
	directives: {
		Ripple
	},
	model: {
		prop: "isAddNewConsultationSidebarActive",
		event: "update:is-add-new-consultation-sidebar-active"
	},
	props: {
		isAddNewConsultationSidebarActive: {
			type: Boolean,
			required: true
		}
	},
	setup(props, { emit }) {
		// USE TOAST
		const { toastNotification } = useNotifications()

		const PRESENTACION = 3
		const ABSOLUCION = 4

		const blankData = [
			{
				name: null,
				stage_id: null,
				start_date: null,
				actual_start_date: null,
				days: null,
				end_date: null
			},
			{
				name: null,
				stage_id: null,
				start_date: null,
				actual_start_date: null,
				days: null,
				end_date: null
			}
		]
		const refFormObserver = ref(null)
		const options = ref({})

		const stageData = ref(JSON.parse(JSON.stringify(blankData)))
		const resetForm = () => setStageData()

		const onlyDateConfig = ref({
			enableTime: true,
			locale: Spanish,
			dateFormat: "d-m-Y H:i",
			minDate: moment().format("DD-MM-YYYY")
		})

		onBeforeMount(async () => {
			await getSelectFilters()
			setStageData()
		})

		const getSelectFilters = async () => {
			const { data } = await axios.get("/selectors/tender-stages")
			options.value = data
		}

		const setStageData = () => {
			const findConsultationStages = options.value.tenderStages.filter((item) =>
				[PRESENTACION, ABSOLUCION].includes(item.value)
			)
			stageData.value = findConsultationStages.map((item) => ({
				name: item.label,
				stage_id: item.value
			}))
		}

		const updateEndDate = (index) => {
			if (
				!stageData.value[index].actual_start_date ||
				!stageData.value[index].days
			) {
				stageData.value[index].end_date = null
				return
			}

			const formattedDate = moment(
				stageData.value[index].actual_start_date,
				"DD-MM-YYYY H:mm"
			)
			if (!formattedDate.isValid()) {
				stageData.value[index].end_date = null
				return
			}

			stageData.value[index].end_date = formattedDate
				.add(stageData.value[index].days, "days")
				.format("DD-MM-YYYY H:mm")
		}

		const onSubmit = () => {
			const payload = { ...stageData.value }

			/*      store.dispatch('app-schedule/createSchedule', payload)
					.then( (response) => {

						emit('refetch-data')
						toastNotification(response.data.message)
						emit('update:is-add-new-consultation-sidebar-active', false)
					})
					.catch( error => {
						const message =  error.response.data.message || 'Error al agregar consultas'
						toastNotification(message, false)
					}) */
		}

		return {
			//REF
			stageData,
			onlyDateConfig,
			refFormObserver,

			//METHODS
			onSubmit,
			updateEndDate,
			resetForm
		}
	}
}
</script>

<style lang="scss" scoped>
#add-new-consultation-sidebar {
	.vs__dropdown-menu {
		max-height: 200px !important;
	}
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>