<template>
	<section>
		<validation-observer
			#default="{ handleSubmit }"
			ref="refEconomicForm"
		>
			<b-form @submit.prevent="handleSubmit(onEconomicSubmit)">
				<b-card
					footer-tag="footer"
					class="mb-0"
				>
					<b-row>
						<b-col
							cols="12"
							md="4"
							class="mb-1"
						>
							<label for="name">
								<strong>MONEDA</strong>
							</label>
							<validation-provider
								#default="{ errors, validate }"
								rules="required"
								name="Moneda"
							>
								<v-select
									id="moneda"
									v-model="formData.currency_type"
									:options="options.currencyTypes"
									@input="updateEconomicTC(0, $event, validate)"
									:state="errors.length > 0 ? false : null"
									:disabled="!!isPublished"
									:readonly="!!isPublished"
									placeholder="Seleccione"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-col>

						<b-col
							cols="12"
							md="4"
							class="mb-1"
						>
							<label for="tipo">
								<strong>TIPO</strong>
							</label>
							<validation-provider
								#default="{ errors }"
								name="Tipo"
								rules="required"
							>
								<v-select
									id="tipo"
									v-model="formData.indicator"
									:options="options.indicators"
									:state="errors.length > 0 ? false : null"
									placeholder="Seleccione"
									:disabled="!!isPublished"
									:readonly="!!isPublished"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-col>

						<b-col
							cols="12"
							md="4"
							class="mb-1"
						>
							<label for="gasto">
								<strong>GASTO GENERAL (GG %)</strong>
							</label>
							<validation-provider
								#default="{ errors }"
								rules="required|min:0"
								name="Gasto General"
							>
								<b-form-input
									id="gasto"
									v-model="formData.general_expenses"
									type="number"
									step="any"
									placeholder="0.00"
									@input="calculateStatistics()"
									:state="errors.length > 0 ? false : null"
									:disabled="!!isPublished"
									:readonly="!!isPublished"
									trim
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-col>

						<b-col
							cols="12"
							md="4"
							class="mb-1"
						>
							<label for="profit">
								<strong>UTILIDAD (UTI %)</strong>
							</label>
							<validation-provider
								#default="{ errors }"
								rules="required|min:0"
								name="Utilidad"
							>
								<b-form-input
									id="profit"
									v-model="formData.profit"
									type="number"
									step="any"
									placeholder="0.00"
									@input="calculateStatistics()"
									:state="errors.length > 0 ? false : null"
									:disabled="!!isPublished"
									:readonly="!!isPublished"
									trim
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-col>

						<b-col
							cols="12"
							md="4"
							class="mb-1"
						>
							<label for="tc">
								<strong>TC de SS</strong>
							</label>
							<validation-provider
								#default="{ errors }"
								rules="double|min:0.0"
								name="Tipo de cambio"
							>
								<b-form-input
									id="tc"
									v-model="formData.exchange_rate"
									@input="calculateStatistics()"
									type="number"
									placeholder="0.0"
									:state="errors.length > 0 ? false : null"
									:disabled="!!isPublished"
									:readonly="!!isPublished || isTcDisabled"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-col>

						<b-col
							cols="12"
							md="4"
							class="mb-1"
						>
							<label for="moneda_ss">
								<strong>MONEDA SS</strong>
							</label>

							<b-form-input
								id="moneda_ss"
								placeholder="-"
								:value="formData.exchange_sign"
								readonly
								disabled
							/>
						</b-col>

						<b-col
							cols="12"
							class="my-1"
						>
							<b-alert
								variant="secondary"
								show
							>
								<h6 class="alert-heading">
									<feather-icon
										icon="InfoIcon"
										class="mr-50"
									/>Registro de partidas
								</h6>
								<div class="alert-body">
									<ul class="mb-0 pl-1">
										<li class="small">
											Descargar
											<b-link
												class="text-primary"
												href="/plantilla_de_partidas.xlsx"
											>
												<strong>plantilla de partidas</strong>
											</b-link>
										</li>
										<li class="small">
											Cargar la plantilla con las partidas registradas para calcular el
											<strong>importe total</strong>
										</li>
										<li class="small">Para actualizar partidas, modifique el mismo archivo y vuelva a cargar</li>
									</ul>
								</div>
							</b-alert>
						</b-col>

						<b-col cols="12">
							<label for="templateFile">
								<strong>CARGAR PLANTILLA DE PARTIDAS</strong>
							</label>
							<b-form-file
								v-model="file"
								placeholder="Seleccione un archivo o suéltelo aquí..."
								accept=".xlsx, .xls"
								drop-placeholder="Suelte archivo aquí ..."
								:disabled="!!isPublished"
								:readonly="!!isPublished"
							/>
						</b-col>

						<b-col
							cols="12"
							v-if="formData.file"
						>
							<b-icon-file
								:filename="formData.file.filename"
								:file-url="formData.file.url"
							/>
						</b-col>

						<b-col cols="12">
							<b-card
								no-body
								class="card-statistics mb-0"
							>
								<b-card-body class="statistics-body">
									<b-row>
										<b-col
											v-for="item in statisticsItems"
											:key="item.icon"
											md="3"
											sm="6"
											class="mb-2 mb-md-0"
											:class="item.customClass"
										>
											<b-media no-body>
												<b-media-aside class="mr-2">
													<b-avatar
														size="48"
														:variant="item.color"
													>{{ item.sign }}</b-avatar>
												</b-media-aside>
												<b-media-body class="my-auto">
													<h4 class="font-weight-bolder mb-0">{{ formatCurrency(item.amount) }}</h4>
													<b-card-text class="font-small-3 mb-0">{{ item.subtitle }}</b-card-text>
												</b-media-body>
											</b-media>
										</b-col>
									</b-row>
								</b-card-body>
							</b-card>
						</b-col>
					</b-row>

					<template #footer>
						<b-col class="d-flex flex-column flex-sm-row justify-content-sm-end">
							<!-- BUTTON SUBMIT -->
							<b-button-loading
								v-if="!isPublished"
								text="GUARDAR CAMBIOS"
								type="submit"
								class="order-sm-2 order-1 ml-sm-2 ml-0"
								:processing="processing"
							></b-button-loading>
						</b-col>
					</template>
				</b-card>
			</b-form>
		</validation-observer>
	</section>
</template>
  
<script>
import { ref, onMounted } from "@vue/composition-api"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { required, min } from "@validations"
import axios from "@axios"
import vSelect from "vue-select"
import BButtonUploadFile from "@core/components/b-button-upload-file/BButtonUploadFile.vue"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import BIconFile from "@core/components/b-icon-file/BIconFile.vue"
import useNotifications from "@notifications"
import { formatCurrency } from "@core/utils/filter"

export default {
	components: {
		ValidationProvider,
		ValidationObserver,

		BButtonUploadFile,
		BButtonLoading,
		BIconFile,

		vSelect,
		required,
		min
	},
	props: {
		tenderId: {
			type: Number,
			require: true
		},
		economicProposal: {
			type: Object,
			default: () => {},
			require: true
		},
		isPublished: {
			type: Number,
			require: true
		}
	},
	setup(props) {
		const { toastNotification } = useNotifications()

		//REFS
		const economicProposal = ref(props.economicProposal)
		const tenderId = ref(props.tenderId)
		const processing = ref(false)
		const economicId = ref(props.economicProposal.id)
		const formData = ref({})
		const refEconomicForm = ref(null)
		const file = ref(null)
		const isTcDisabled = ref(true)
		const isDolar = ref(false)
		const statisticsItems = ref([
			{
				color: "light-primary",
				sign: "S/",
				amount: 0,
				subtitle: "COSTO DIRECTO",
				customClass: "mb-2 mb-xl-0"
			},
			{
				color: "light-info",
				amount: 0,
				sign: "S/",
				subtitle: "GASTO GENERAL",
				customClass: "mb-2 mb-xl-0"
			},
			{
				sign: "S/",
				color: "light-danger",
				amount: 0,
				subtitle: "UTILIDAD",
				customClass: "mb-2 mb-sm-0"
			},
			{
				sign: "S/",
				color: "light-success",
				amount: 0,
				subtitle: "IMPORTE TOTAL",
				customClass: ""
			}
		])
		const subTotal = ref(0)

		const options = ref({})

		onMounted(async () => {
			await getSelectFilters()
			setData()
		})

		const setData = () => {
			subTotal.value = parseFloat(economicProposal.value.subtotal || 0)

			const {
				id,
				file,
				currency_type,
				exchange_rate,
				general_expenses,
				indicator: indicatorSaved,
				profit,
				subtotal
			} = economicProposal.value

			const indicator = options.value.indicators.find(
				(item) => item.value === indicatorSaved
			)

			let exchange_sign = null
			if (currency_type) {
				exchange_sign = options.value.currencyTypes.find(
					(item) => item.value === currency_type.value
				).sign
			}

			formData.value = {
				id,
				file,
				currency_type,
				exchange_rate,
				indicator,
				general_expenses: general_expenses
					? (parseFloat(general_expenses) * 100).toFixed(2)
					: 0,
				profit: profit ? (parseFloat(profit) * 100).toFixed(2) : 0,
				subtotal: subtotal ? parseFloat(subtotal) : 0,
				exchange_sign
			}

			calculateStatistics()
		}

		const getSelectFilters = async () => {
			const response = await axios.get("/selectors/currency-types")

			options.value = { ...response.data }
			options.value.indicators = [
				{
					label: "PRECIO UNITARIO (PxQ)",
					value: "PRECIO UNITARIO (PxQ)"
				},
				{
					label: "SUMA ALZADA",
					value: "SUMA ALZADA"
				}
			]
		}

		const calculateStatistics = () => {
			statisticsItems.value[0].amount = formData.value.subtotal
			statisticsItems.value[1].amount =
				formData.value.subtotal * (formData.value.general_expenses / 100)
			statisticsItems.value[2].amount =
				formData.value.subtotal * (formData.value.profit / 100)

			statisticsItems.value[3].amount =
				statisticsItems.value[0].amount +
				statisticsItems.value[1].amount +
				statisticsItems.value[2].amount

			if (isDolar.value) {
				statisticsItems.value[3].amount *= parseFloat(
					formData.value.exchange_rate
				)
			}
		}

		const updateEconomicTC = (index, val) => {
			if (!val) {
				formData.value.exchange_rate = 0
				formData.value.exchange_sign = null
				isTcDisabled.value = true
				isDolar.value = false
				return
			}

			if (val.label === "DÓLARES") {
				formData.value.exchange_sign = val.sign
				isTcDisabled.value = false
				isDolar.value = true
				return
			}

			formData.value.exchange_rate = null
			formData.value.exchange_sign = val.sign
			isTcDisabled.value = true
			isDolar.value = false
		}

		const onEconomicSubmit = async () => {
			processing.value = true

			let error = false
			let message = null

			const isValid = await refEconomicForm.value.validate()

			if (!isValid) {
				message = "Complete los campos requeridos"
				error = true
			}

			if (
				!error &&
				isDolar.value &&
				!parseFloat(formData.value.exchange_rate)
			) {
				message = "Complete el tipo de cambio"
				error = true
			}

			if (error) {
				processing.value = false
				toastNotification(message, false)
				return
			}

			try {
				const payload = {
					...formData.value
				}

				const sendFormData = new FormData()

				sendFormData.append("file", file.value)
				sendFormData.append("tender_id", tenderId.value)
				sendFormData.append("published", false)
				sendFormData.append("data", JSON.stringify(payload))

				const response = await axios.put(
					`/economic-proposals/${economicId.value}/template`,
					sendFormData,
					{
						headers: { "Content-Type": "multipart/form-data" }
					}
				)

				const { subtotal, has_file, file_original_name, file_id, file_url } =
					response.data.economicData

				if (has_file) {
					formData.value.file = {
						id: file_id,
						filename: file_original_name,
						url: file_url
					}
					file.value = null
				}

				formData.value.profit = parseFloat(payload.profit).toFixed(2)
				formData.value.general_expenses = parseFloat(
					payload.general_expenses
				).toFixed(2)
				formData.value.subtotal = subtotal

				calculateStatistics()

				toastNotification(response.data.message)
			} catch (error) {
				const message =
					error.response.data.message || "Error al actualizar la propuesta"
				toastNotification(message, false)
			} finally {
				processing.value = false
			}
		}

		return {
			//REFS
			refEconomicForm,
			formData,
			statisticsItems,
			options,
			file,
			isTcDisabled,
			processing,

			//METHODS
			calculateStatistics,
			updateEconomicTC,
			onEconomicSubmit,
			formatCurrency
		}
	}
}
</script>
  
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>