<template>
	<section>
		<stage-list-add-new
			:is-add-new-consultation-sidebar-active.sync="isAddNewConsultationSidebarActive"
			@refetch-data="refetchData"
		/>

		<b-card
			footer-tag="footer"
			class="mb-0"
		>
			<b-row>
				<b-col
					cols="12"
					class="d-flex justify-content-between align-items-center mb-2"
				>
					<label>
						<strong>CRONOGRAMA DE ETAPAS</strong>
					</label>

					<!-- <b-button
						variant="primary"
						v-ripple.400="'rgba(113, 102, 240, 0.15)'"
						@click="isAddNewConsultationSidebarActive = true"
						 class="ml-auto"
					>
						<span class="text-nowrap">Agregar Consultas</span>                   
					</b-button>-->
				</b-col>

				<b-col cols="12">
					<b-table
						ref="refScheduleTable"
						responsive
						:fields="fields"
						:items="scheduleData"
						bordered
					>
						<template #head(start_date)="data">
							<span v-html="data.label"></span>
						</template>

						<template #head(actual_start_date)="data">
							<span v-html="data.label"></span>
						</template>

						<template #head(days)="data">
							<span v-html="data.label"></span>
						</template>

						<template #head(end_date)="data">
							<span v-html="data.label"></span>
						</template>

						<template #head(actual_end_date)="data">
							<span v-html="data.label"></span>
						</template>

						<template #head(actual_days)="data">
							<span v-html="data.label"></span>
						</template>

						<template #head(days_difference)="data">
							<span v-html="data.label"></span>
						</template>

						<template #cell(start_date)="data">
							<div
								class="text-nowrap"
								:class="{ 'text-muted': !data.item.start_date }"
							>{{ formatStringToDate(data.item.start_date) }}</div>
						</template>

						<template #cell(actual_start_date)="data">
							<div
								class="text-nowrap"
								:class="{ 'text-muted': !data.item.actual_start_date }"
							>{{ formatStringToDate(data.item.actual_start_date) }}</div>
						</template>

						<template #cell(days)="data">
							<div
								class="text-nowrap"
								:class="{ 'text-muted': !data.item.days }"
							>{{ data.item.days }}</div>
						</template>

						<template #cell(end_date)="data">
							<div
								class="text-nowrap"
								:class="{ 'text-muted': !data.item.end_date }"
							>{{ formatStringToDate(data.item.end_date) }}</div>
						</template>

						<template #cell(actual_end_date)="data">
							<div
								class="text-nowrap"
								:class="{ 'text-muted': !data.item.actual_end_date }"
							>{{ formatStringToDate(data.item.actual_end_date) }}</div>
						</template>

						<template #cell(days_difference)="data">
							<div class="text-nowrap">{{ data.item.days_difference }}</div>
						</template>

						<template #cell(state)="data">
							<b-button
								:id="`tooltip-state-${data.item.id}`"
								v-ripple.400="'rgba(40, 199, 111, 0.15)'"
								:variant="
									!isPublished ? 'flat-secondary' : 
									isOpen(data.item.actual_start_date, data.item.actual_end_date) ? 
									'flat-success' : 'flat-danger'"
								class="btn-icon rounded-circle"
							>
								<feather-icon
									:icon="!isPublished ? 'LockIcon' : isOpen(data.item.actual_start_date, data.item.actual_end_date) ? 'UnlockIcon' : 'LockIcon'"
								/>
							</b-button>
							<b-tooltip
								:target="`tooltip-state-${data.item.id}`"
								triggers="hover"
								no-fade
							>{{ !isPublished ? '-' : isOpen(data.item.actual_start_date, data.item.actual_end_date) ? 'En proceso' : 'Cerrado' }}</b-tooltip>
						</template>

						<template #cell(actions)="data">
							<div class="text-nowrap">
								<b-button
									variant="outline-primary"
									class="btn-icon rounded-circle"
									@click="openScheduleModal(data.item)"
								>
									<feather-icon icon="SettingsIcon" />
								</b-button>
							</div>
						</template>
					</b-table>
				</b-col>
			</b-row>
		</b-card>

		<b-modal
			ref="refScheduleModal"
			title-tag="div"
			header-bg-variant="primary"
			header-text-variant="white"
			centered
			title="CONFIGURACIÓN DE CRONOGRAMA"
			@hidden="resetModal"
			no-close-on-backdrop
			no-close-on-esc
			hide-header-close
			hide-footer
			size="md"
		>
			<template #default="{ hide }">
				<validation-observer
					#default="{ handleSubmit }"
					ref="refScheduleForm"
				>
					<b-form
						@submit.prevent="handleSubmit(onSubmit)"
						@reset.prevent="resetModal"
					>
						<b-row>
							<b-col
								cols="12"
								class="mb-2"
							>
								<label for="stage">
									<strong>ETAPA</strong>
								</label>
								<b-form-input
									id="stage"
									:value="stageData.tender_stage_name"
									disabled
									readonly
								/>
							</b-col>

							<b-col
								cols="12"
								class="mb-2"
							>
								<label for="title">
									<strong>FECHA INICIAL PROGRAMADA</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									name="Fecha Inicial Programada"
									:rules="isPublished ? '':'required'"
								>
									<flat-pickr
										id="start_date"
										class="form-control"
										:class="{'muted': isPublished === 1}"
										:config="onlyDateConfig"
										v-model="stageData.start_date"
										:state="errors.length > 0 ? false:null"
										:disabled="isPublished ? true : false"
										:readonly="isPublished ? true : false"
										placeholder="DD-MM-YYYY"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<!-- 	<b-col
								cols="12"
								class="mb-2"
							>
								<label for="description">
									<strong>DÍAS POR ETAPA</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									:rules="isPublished ? '':'required|integer|min:1'"
									name="Días por etapa"
								>
									<b-form-input
										v-model="stageData.days"
										:state="errors.length > 0 ? false : null"
										placeholder="-"
										@input="changeDates()"
										:disabled="isPublished ? true : false"
										:readonly="isPublished ? true : false"
										trim
									/>

									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>-->

							<b-col
								cols="12"
								:class="{'mb-2': isPublished === 0}"
							>
								<label for="end_date">
									<strong>FECHA FINAL PROGRAMADA</strong>
								</label>
								<validation-provider
									#default="{ errors }"
									name="Fecha Final Programada"
									:rules="isPublished ? '':'required'"
								>
									<flat-pickr
										id="end_date"
										class="form-control"
										:class="{'muted': isPublished === 1}"
										:config="onlyDateConfig"
										v-model="stageData.end_date"
										:state="errors.length > 0 ? false : null"
										placeholder="DD-MM-YYYY"
										:disabled="isPublished ? true : false"
										:readonly="isPublished ? true : false"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-col>

							<b-col
								v-if="isPublished"
								cols="12"
								class="my-1"
							>
								<hr />
							</b-col>

							<b-col
								v-if="isPublished"
								cols="12"
								class="mb-2"
							>
								<label for="actual_start_date">
									<strong>FECHA INICIAL EJECUTADA</strong>
								</label>
								<flat-pickr
									id="actual_start_date"
									class="form-control"
									:class="{'muted': isPublished === 0}"
									:config="onlyDateConfig"
									v-model="stageData.actual_start_date"
									placeholder="DD-MM-YYYY"
								/>
							</b-col>

							<b-col
								v-if="isPublished"
								cols="12"
								class="mb-2"
							>
								<label for="actual_end_date">
									<strong>FECHA FINAL EJECUTADA</strong>
								</label>
								<flat-pickr
									id="actual_end_date"
									class="form-control"
									:class="{'muted': isPublished === 0}"
									:config="onlyDateConfig"
									v-model="stageData.actual_end_date"
									placeholder="DD-MM-YYYY"
								/>
							</b-col>

							<b-col
								v-if="isPublished"
								cols="12"
								class="mb-2"
							>
								<label for="comment">
									<strong>Comentario (opcional)</strong>
								</label>
								<b-form-textarea
									:value="stageData.comment"
									id="comment"
									placeholder="Escriba aquí ... "
									rows="3"
								/>
							</b-col>
						</b-row>

						<hr class="mt-0" />
						<div class="d-flex justify-content-end">
							<b-button
								v-ripple.400="'rgba(186, 191, 199, 0.15)'"
								variant="outline-secondary"
								class="mr-2"
								type="button"
								@click="hide"
							>CANCELAR</b-button>

							<b-button-loading
								text="GUARDAR"
								type="submit"
								:processing="processing"
							></b-button-loading>
						</div>
					</b-form>
				</validation-observer>
			</template>
		</b-modal>
	</section>
</template>

<script>
import { formatStringToDate, formatDateToString } from "@core/utils/filter"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import { ref, onUnmounted, onMounted } from "@vue/composition-api"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { required, integer, min } from "@validations"
import scheduleStoreModule from "./scheduleStoreModule"
import StageListAddNew from "./StageListAddNew.vue"
import { Spanish } from "flatpickr/dist/l10n/es"
import flatPickr from "vue-flatpickr-component"
import useNotifications from "@notifications"
import Ripple from "vue-ripple-directive"
import { VBTooltip } from "bootstrap-vue"
import { v4 as uuidv4 } from "uuid"
import vSelect from "vue-select"
import router from "@/router"
import moment from "moment"
import store from "@/store"
import axios from "@axios"

export default {
	components: {
		ValidationProvider,
		ValidationObserver,

		BButtonLoading,
		StageListAddNew,

		flatPickr,
		required,
		integer,
		min,
		vSelect
	},
	directives: {
		"b-tooltip": VBTooltip,
		Ripple
	},
	props: {
		tenderId: {
			type: Number,
			required: true
		},
		scheduleData: {
			type: Array,
			required: true
		},
		isPublished: {
			type: Number,
			required: true
		}
	},
	setup(props, { emit }) {
		// USE TOAST
		const { toastNotification } = useNotifications()

		// REFS
		const tenderId = ref(props.tenderId)
		const isAddNewConsultationSidebarActive = ref(false)
		const processing = ref(false)
		const refScheduleTable = ref(null)
		const refScheduleModal = ref(null)
		const refScheduleForm = ref(null)
		const items = ref([])
		const fields = ref([])
		const options = ref({})

		const stageData = ref({
			id: null,
			days: null,
			end_date: null,
			start_date: null,
			actual_start_date: null,
			tender_stage_id: null,
			tender_stage_name: null,
			actual_end_date: null
		})

		const onlyDateConfig = ref({
			enableTime: true,
			time_24hr: true,
			locale: Spanish,
			dateFormat: "d-m-Y H:i",
			minDate: "today",
			minuteIncrement: 10
		})

		const other_stages = ref([
			{
				id: null,
				tender_stage_id: null,
				tender_stage_name: null,
				start_date: null,
				days_duration: null,
				end_date: ""
			}
		])

		let interval = null

		const SCHEDULE_APP_STORE_MODULE_NAME = "app-schedule"

		// REGISTER MODULE
		if (!store.hasModule(SCHEDULE_APP_STORE_MODULE_NAME))
			store.registerModule(SCHEDULE_APP_STORE_MODULE_NAME, scheduleStoreModule)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			clearInterval(interval)
			if (store.hasModule(SCHEDULE_APP_STORE_MODULE_NAME))
				store.unregisterModule(SCHEDULE_APP_STORE_MODULE_NAME)
		})

		const styleColumns = { thClass: "text-center", tdClass: "text-center" }

		fields.value = [
			{
				key: "tender_stage_name",
				label: "Etapa",
				thClass: styleColumns.thClass
			},
			{
				key: "state",
				label: "Estado",
				...styleColumns
			},
			{
				key: "start_date",
				label: "Fecha Inicial </br> Programada",
				...styleColumns
			},
			{
				key: "end_date",
				label: "Fecha Final </br> Programada",
				...styleColumns
			},
			{ key: "days", label: "Días</br>programados", ...styleColumns },
			{
				key: "actual_start_date",
				label: "Fecha Inicial </br> Ejecutada",
				...styleColumns
			},
			{
				key: "actual_end_date",
				label: "Fecha Final </br> Ejecutada",
				...styleColumns
			},
			{ key: "actual_days", label: "Días</br>ejecutados", ...styleColumns },
			{
				key: "days_difference",
				label: "Programado </br> vs </br> Ejecutado",
				...styleColumns
			},
			{ key: "actions", label: "Opciones", ...styleColumns }
		]

		const convertToRomanNumerals = (num) => {
			const romanValues = [
				{ value: 10, sign: "X" },
				{ value: 9, sign: "IX" },
				{ value: 5, sign: "V" },
				{ value: 4, sign: "IV" },
				{ value: 1, sign: "I" }
			]

			let result = ""

			for (const { value, sign } of romanValues) {
				while (num >= value) {
					result += sign
					num -= value
				}
			}

			return result
		}

		const openScheduleModal = (item) => {
			onlyDateConfig.value.minDate =
				item.start_date || item.end_date ? null : "today"

			stageData.value = {
				...item,
				start_date: formatStringToDate(item.start_date),
				end_date: formatStringToDate(item.end_date),
				actual_start_date: formatStringToDate(item.actual_start_date),
				actual_end_date: formatStringToDate(item.actual_end_date)
			}

			refScheduleModal.value.show()
		}

		const resetModal = () => (stageData.value = {})

		const refetchData = () => refScheduleTable.value.refresh()

		const changeDates = () => {
			if (!stageData.value.start_date || !stageData.value.days) {
				stageData.value.end_date = null
				return
			}

			const formattedDate = moment(
				stageData.value.start_date,
				"DD-MM-YYYY H:mm"
			)
			if (!formattedDate.isValid()) {
				stageData.value.end_date = null
				return
			}

			stageData.value.end_date = formattedDate
				.add(stageData.value.days, "days")
				.format("DD-MM-YYYY H:mm")
		}

		const addConsultation = () => {
			// 3: PRESENTACIÓN DE CONSULTAS, 4: ABSOLUCIÓN DE CONSUTLAS
			const findStages = options.value.tenderStages.filter((item) =>
				[3, 4].includes(item.value)
			)
			const nextIndex =
				other_stages.value.length > 0
					? other_stages.value[other_stages.value.length - 1]
							.index_consultation + 1
					: 2
			other_stages.value.push(
				{
					uid: uuidv4(),
					index_consultation: nextIndex,
					tender_stage_id: findStages[0].value,
					tender_stage_name: findStages[0].label,
					start_date: null,
					days_duration: null,
					end_date: ""
				},
				{
					uid: uuidv4(),
					index_consultation: nextIndex,
					tender_stage_id: findStages[1].value,
					tender_stage_name: findStages[1].label,
					start_date: null,
					days_duration: null,
					end_date: ""
				}
			)
		}

		const removeConsultation = (index) => {
			if (index >= 1) {
				other_stages.value.splice(index - 1, 2)
			}
		}

		const isOpen = (start_date, end_date) => {
			const now = moment()
			const start = moment(start_date, "YYYY-MM-DD HH:mm")
			const end = moment(end_date, "YYYY-MM-DD HH:mm")
			return now.isBetween(start, end)
		}

		onMounted(() => {
			interval = setInterval(() => {
				items.value = [...items.value]
			}, 60000)
		})

		const onSubmit = async () => {
			processing.value = true

			let payload = {
				...stageData.value,
				published: false,
				tender_id: tenderId.value
			}

			store
				.dispatch("app-schedule/updateSchedule", { id: payload.id, payload })
				.then((response) => {
					const { scheduleData } = response.data

					payload = { ...payload, ...scheduleData }

					const index = props.scheduleData.findIndex(
						(item) => item.id === payload.id
					)

					emit("update-schedule", { index, payload })

					refScheduleModal.value.hide()
					toastNotification(response.data.message)
					refScheduleTable.value.refresh()
				})
				.catch((error) => {
					const message =
						error.response.data.message || "Error al actualizar el cronograma"
					toastNotification(message, false)
				})
				.finally((_) => (processing.value = false))
		}
		return {
			// DATA
			onlyDateConfig,
			isAddNewConsultationSidebarActive,
			processing,
			stageData,
			options,

			// REFS
			refScheduleForm,
			refScheduleModal,
			refScheduleTable,
			other_stages,
			items,
			fields,
			formatStringToDate,
			formatDateToString,

			// METHODS
			openScheduleModal,
			addConsultation,
			removeConsultation,
			changeDates,
			onSubmit,
			resetModal,
			refetchData,
			isOpen
		}
	}
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>