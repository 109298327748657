<template>
	<section>
		<b-card>
			<app-collapse type="margin">
				<app-collapse-item
					title="DATOS GENERALES"
					isVisible
					class="text-primary"
				>
					<b-row>
						<b-col
							cols="12"
							class="my-1"
						>
							<b-alert
								variant="info"
								show
							>
								<h6 class="alert-heading">
									<feather-icon
										icon="InfoIcon"
										class="mr-50"
									/>
									<span
										class="small"
									>La ejecución del servicio se dará acabo en la(s) unidad(es) seleccionada(s)</span>
								</h6>
							</b-alert>
						</b-col>
						<!-- TENDER TITLE -->
						<b-col
							cols="12"
							class="mb-1"
						>
							<label for="name">
								<strong>OBJETO DEL CONTRATO</strong>
							</label>
							<b-form-input
								id="name"
								placeholder="LICITACION DE PRUEBA"
								:value="tenderData.title"
								disabled
							/>
						</b-col>

						<!-- CONTRACT TYPE -->
						<b-col
							cols="12"
							md="6"
							lg="7"
							class="mb-1"
						>
							<label for="contractType">
								<strong>TIPO DE CONTRATO</strong>
							</label>
							<b-form-input
								id="contractType"
								:value="tenderData.contract_name"
								placeholder="LOCACIÓN DE SERVICIOS"
								disabled
							/>
						</b-col>

						<!-- LOCATION -->
						<b-col
							cols="12"
							md="6"
							lg="5"
							class="mb-1"
						>
							<label for="location">
								<strong>EJECUCIÓN DE SERVICIO</strong>
							</label>
							<v-select
								id="location"
								:value="tenderData.locations"
								:options="options.locations"
								multiple
								disabled
								readonly
							/>
						</b-col>

						<!-- DESCRIPTION -->
						<b-col cols="12">
							<label for="description">
								<strong>DESCRIPCIÓN</strong>
							</label>
							<b-form-textarea
								id="description"
								:value="tenderData.description"
								placeholder="detalle"
								disabled
							></b-form-textarea>
						</b-col>
					</b-row>
				</app-collapse-item>

				<app-collapse-item
					title="INVITACIÓN"
					isVisible
					class="text-primary"
				>
					<b-row>
						<b-col>
							<h6>Estimados Señores,</h6>
							<b-form-textarea
								id="description"
								v-model="tenderData.invitation_message"
								placeholder="Ingrese texto de invitación..."
								:disabled="isPublished ? true : false"
								:readonly="isPublished ? true : false"
								rows="5"
								trim
							/>
						</b-col>
					</b-row>
				</app-collapse-item>

				<app-collapse-item
					title="CRONOGRAMA"
					isVisible
					class="text-primary"
				>
					<b-row>
						<b-col cols="6">
							<b-table
								class="table-sm"
								ref="refScheduleTable"
								responsive
								:fields="fields"
								:items="scheduleData"
								bordered
							>
								<template #cell(actual_start_date)="data">
									<div
										class="text-nowrap"
									>{{ data.item.actual_start_date ? moment(data.item.actual_start_date).format("DD-MM-YYYY H:mm") : 'DD-MM-YYYY' }}</div>
								</template>
								<template #cell(actual_end_date)="data">
									<div
										class="text-nowrap"
									>{{ data.item.actual_end_date ? moment(data.item.actual_end_date).format("DD-MM-YYYY H:mm") : 'DD-MM-YYYY' }}</div>
								</template>
							</b-table>
						</b-col>
					</b-row>
				</app-collapse-item>
				<app-collapse-item
					title="DOCUMENTOS"
					isVisible
					class="text-primary"
				>
					<b-row>
						<b-col cols="6">
							<label>
								<strong>DOCUMENTOS GENERALES</strong>
							</label>
							<b-button-upload-file
								:tender-id="tenderId"
								:tender-props="{ isGeneral: true, isFromInvitation: true }"
								:button-props="{ icon: 'PaperclipIcon', text: 'VER DOCUMENTOS GENERALES', block : true }"
							/>
						</b-col>
					</b-row>
				</app-collapse-item>
			</app-collapse>

			<b-row>
				<b-col class="mt-3">
					<b-button-loading
						v-if="!isPublished && $can('manage', 'all')"
						text="ENVIAR INVITACIONES"
						:block="true"
						size="lg"
						:processing="processing"
						@process-action="sendInvitations"
					/>
				</b-col>
			</b-row>
		</b-card>
	</section>
</template>

<script>
import { ref, onBeforeMount } from "@vue/composition-api"
import { VBTooltip } from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue"
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue"
import BButtonUploadFile from "@core/components/b-button-upload-file/BButtonUploadFile.vue"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import useNotifications from "@notifications"
import VueSweetalert2 from "vue-sweetalert2"
import moment from "moment"
import router from "@/router"
import axios from "@axios"
import vSelect from "vue-select"
import Vue from "vue"

Vue.use(VueSweetalert2)

export default {
	components: {
		AppCollapse,
		AppCollapseItem,
		BButtonUploadFile,
		BButtonLoading,

		vSelect
	},
	directives: {
		"b-tooltip": VBTooltip,
		Ripple
	},
	data() {
		return {
			moment
		}
	},
	props: {
		tenderId: {
			type: Number,
			required: true
		},
		isPublished: {
			type: Number,
			default: 0
		},
		scheduleData: {
			type: Array,
			required: true
		},
		tenderData: {
			type: Object,
			required: true
		}
	},
	setup(props) {
		const { swalNotification, toastNotification } = useNotifications()

		const options = ref({})
		const tenderId = ref(props.tenderId)
		const tenderData = ref(props.tenderData)
		const processing = ref(false)
		const fields = ref([])

		onBeforeMount(async () => {
			await getSelectFilters()
			setStageDataTable()
		})

		const getSelectFilters = async () => {
			const response = await axios.get("/selectors/locations")
			options.value = {
				...response.data
			}
		}

		const setStageDataTable = () => {
			const styleColumns = { thClass: "text-center", tdClass: "text-center" }

			fields.value = [
				{
					key: "tender_stage_name",
					label: "Etapa",
					thClass: styleColumns.thClass
				},
				{ key: "actual_start_date", label: "Fecha Inicial", ...styleColumns },
				{ key: "actual_end_date", label: "Fecha Límite", ...styleColumns }
			]
		}

		const sendInvitations = async () => {
			processing.value = true

			if (!tenderData.value.invitation_message) {
				processing.value = false
				toastNotification("Por favor, ingrese un mensaje de invitación", false)
				return
			}

			Vue.swal({
				title: "¿Está seguro(a) de enviar las invitaciones?",
				html: "<small>¡Recuerde que luego no podrá realizar ninguna modificación!</small>",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sí, enviar!",
				cancelButtonText: "Cancelar",
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1"
				},
				showClass: {
					popup: "animate__animated animate__tada"
				},
				buttonsStyling: false
			}).then(async (result) => {
				if (result.value) {
					try {
						const stage = tenderData.value.schedules[0]
						const currentStageData = {
							stage_id: stage.tender_stage_id,
							start_date: stage.actual_start_date,
							end_date: stage.actual_end_date
						}

						const payload = {
							currentStageData,
							tender_id: tenderId.value,
							invitation_message: tenderData.value.invitation_message,
							sending: true
						}
						const response = await axios.put("/invitations/send", payload)
						swalNotification(response.data.message)
						router.push({ name: "tenders-list" })
					} catch (error) {
						processing.value = false
						const message =
							error.response.data.message ||
							"Ocurrió un error al enviar las invitaciones"
						swalNotification(message, false)
					}
				}
				processing.value = false
			})
		}

		return {
			// REFS
			options,
			fields,
			processing,

			// METHODS
			sendInvitations
		}
	}
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>